import React from "react";
import CodeBox from "./CodeBox";
import VerificationBox from "../../ui/organisms/VerificationBox";
import ClaimBox from "./ClaimBox";
import AltVerification from "./AltVerification";
import WelcomeBonus from "./WelcomeBonus";
import OfferCode from "./OfferCode";
import SignIn from "./SignIn";
import ikLogo from "../../../assets/images/checkout/inkind_white.png";
import ikLogoWhite from "../../../assets/images/icons/inkind-white-logo.png";
import fetch from "isomorphic-fetch";
import numeral from "numeral";
import downArrow from "../../../assets/images/icons/angle-down.svg";
import mobilePassI from "../../../assets/images/pass/offers-mobile-bg.jpg";
import { smartJoin } from "@lib/helpers";
import { InKindNetworkError } from "../../helpers/InKindNetworkError";
import useIsMobile from "../../hooks/useIsMobile";
import WelcomeBack from "./WelcomeBack";
import ReturnUserNoLongerAvailable from "./ReturnUserNoLongerAvailable";
import NewUserNoLongerAvailable from "./NewUserNoLongerAvailable";
import withEventsLogger from "../events/withEventsLogger";
import useDeviceId from "../events/useDeviceId";
import { useInView } from "react-intersection-observer";
import NoAccount from "./NoAccount";
import useSessionId from "../events/useSessionId";
import { isEmpty } from "lodash-es";
import EmailClaimSuccess from "./EmailClaimSuccess";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const App = ({
  offer,
  claim,
  cloudinaryPath,
  smsNumber,
  enterprise,
  ikstate,
  locationCount,
  logEvents,
  heroImageUrl,
  coBrandedLogoUrl,
  coBrandedOfferClaim,
  coBrandedLogoLandscape,
  offerAmplitudePresenter,
  claimResponse,
  userEmail,
  offerResponse,
}) => {
  const inkindLogo = require(
    `../../../assets/images/enterprise/${enterprise}/icons/ocp-logo.png`,
  );

  const ocpSlidesExperiment = offerAmplitudePresenter?.enable_slides === true;

  const offerBg = coBrandedOfferClaim
    ? heroImageUrl
    : require(
        `../../../assets/images/enterprise/${enterprise}/icons/offerbg-min.png`,
      );

  const offerCodeBg = require(
    `../../../assets/images/enterprise/${enterprise}/icons/offercodeBG.jpg`,
  );

  const offer1 = require("../../../assets/images/icons/offer-image1.png");
  const offer2 = require("../../../assets/images/icons/offer-image2.png");
  const offer3 = require("../../../assets/images/icons/offer-image3.png");
  const offer4 = require("../../../assets/images/icons/offer-image4.png");

  const previousSlide = require("../../../assets/images/icons/previous-slide.png");
  const nextSlide = require("../../../assets/images/icons/next-slide.png");

  const starRating = require("../../../assets/images/icons/star-rating.png");

  let claimUse = claim;
  const decodedHash = String(
    !!window.location.hash && window.location.hash.substring(1).length > 0
      ? atob(window.location.hash.split("?")[0].substring(1))
      : "",
  ).toUpperCase();
  const hasHashedClaimCode =
    !claimUse &&
    decodedHash.length > 0 &&
    /^(([a-z0-9\.!#$%&'*+/=?^_`{|}~-]+@[a-z-]+(\.[a-z]{2,})+)|(\+?[a-z0-9]+))$/i.test(
      decodedHash,
    );
  if (hasHashedClaimCode) {
    claimUse = decodedHash;
  }

  const [loading, setLoading] = React.useState(true);
  const [offerCode, setOfferCode] = React.useState(
    offer ? String(offer).replace(/[\W_]+/g, "") : offer,
  );
  const [claimCode, setClaimCode] = React.useState(
    !!claimUse && !hasHashedClaimCode
      ? String(claimUse)
          .replace(/[\W_]+/g, "")
          .toUpperCase()
      : claimUse,
  );

  const [inkindStateName, setInkindStateName] = React.useState(
    ikstate?.enterprise?.name,
  );
  const [inkindStateDomain, setInkindStateDomain] = React.useState(
    "https://app." + ikstate?.enterprise?.domain,
  );
  const [inkindStatePrivacyUrl, setInkindStatePrivacyUrl] = React.useState(
    ikstate?.enterprise?.privacy_url,
  );
  const [inkindStateTermsUrl, setInkindStateTermsUrl] = React.useState(
    ikstate?.enterprise?.terms_url,
  );
  const [inkindStateSupportUrl, setInkindStateSupportUrl] = React.useState(
    ikstate?.enterprise?.support_url,
  );
  const [inkindStateAppStoreUrl, setInkindStateAppStoreUrl] = React.useState(
    ikstate?.enterprise?.app_store_url,
  );
  const [inkindStatePlayStoreUrl, setInkindStatePlayStoreUrl] = React.useState(
    ikstate?.enterprise?.play_store_url,
  );
  const [inkindStateRequiredEmails, setInkindStateRequiredEmails] =
    React.useState(ikstate?.enterprise?.required_email_ending);

  const [inkindStateRequiredEmailsDisplay, setInkindStateRequiredEmailDisplay] =
    React.useState(
      ikstate?.enterprise?.required_email_display?.length > 0
        ? `Sorry, a valid ${smartJoin(
            ikstate.enterprise.required_email_display,
            "or",
          )} email address is required to continue.`
        : "Please enter a valid email address.",
    );

  const [slug, setSlug] = React.useState(null);
  const [offerId, setOfferId] = React.useState(null);
  const [brandId, setBrandId] = React.useState(null);
  const [brand, setBrand] = React.useState("inKind restaurants");
  const [value, setValue] = React.useState("$25.00");
  const [minimumAmount, setMinimumAmount] = React.useState("");
  const [minimumSpend, setMinimumSpend] = React.useState(false);
  const [sendType, setSendType] = React.useState("email");
  const [displayState, setDisplayState] = React.useState("");
  const [errorState, setErrorState] = React.useState(false);
  const [phoneNum, setPhoneNum] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [imageState, setImageState] = React.useState("");
  const [offerData, setOfferData] = React.useState("");
  const [ppData, setPpData] = React.useState(false);
  const [groupHero, setGroupHero] = React.useState("#");
  const [groupLogo, setGroupLogo] = React.useState("#");
  const [desktopGroupLogo, setDesktopGroupLogo] = React.useState("");
  const [tiers, setTiers] = React.useState([]);
  const [idValue, setIdValue] = React.useState("");
  const [tokenUse, setTokenUse] = React.useState("");
  const [restrictNew, setRestrictNew] = React.useState(false);
  const [shareUrl, setShareUrl] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [redeemableDays, setRedeemableDays] = React.useState("");
  const [limitedHours, setLimitedHours] = React.useState(false);
  const [redeemableShortDays, setRedeemableShortDays] = React.useState("");
  const [restrictionText, setRestrictionText] = React.useState("");
  const [restrictionHighlights, setRestrictionHighlights] = React.useState([]);
  const [nextEligibleDate, setNextEligibleDate] = React.useState("");
  const [isTracking, setIsTracking] = React.useState(false);
  const [expiration, setExpiration] = React.useState({
    mode: "days",
    days: 30,
  });
  const [videoUrl, setVideoUrl] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [codeSubmitting, setCodeSubmitting] = React.useState(false);
  const [showError, handleShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [smsOptIn, setSmsOptIn] = React.useState(false);
  const [userPassword, setUserPassword] = React.useState("");
  const [mapSet, setMapSet] = React.useState(false);
  const [offerType, setOfferType] = React.useState("");
  const [offerStatus, setOfferStatus] = React.useState("");
  const [offerGeo, setOfferGeo] = React.useState("");
  const [daysToRedeem, setDaysToRedeem] = React.useState(null);
  const isMobile = useIsMobile();
  let ikname = inkindStateName.replace(" Staging", "");
  const sessionId = useSessionId();

  const body = document
    .getElementsByTagName("body")[0]
    .classList.add("offers-body");

  React.useEffect(() => {
    handleInitDisplay();
  }, []);
  const deviceId = useDeviceId();

  function handleAmplitudePost(events) {
    if (Array.isArray(events) && events.length > 0) {
      events[0].session_id = sessionId;
    }
    fetch("/api/v5/events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-InKind-Session": sessionId,
        "X-Inkind-Device": events[0].device_id,
      },
      body: JSON.stringify({
        events: events,
        time: Date.now(),
      }),
    })
      .then(function (response) {
        if (!response.ok)
          throw new Error(`HTTP error! Status: ${response.status}`);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleAmplitude = async (eventName, eventProperties) => {
    if (eventName === "claim_offer" && email) {
      eventProperties.email = email;
    }
    if (eventName === "claim_offer" && phoneNum) {
      eventProperties.phone_number = phoneNum;
    }
    await logEvents([
      {
        session_id: sessionId,
        event_name: eventName,
        device_id: deviceId,
        event_properties: {
          offer_code: offerCode,
          offer_amount: value.replace("$", ""),
          min_spend_amt: minimumAmount.replace("$", ""),
          min_spend_required: minimumSpend,
          days_to_redeem: daysToRedeem,
          new_user_offer: restrictNew,
          offer_type: offerType,
          variable_day_offer: limitedHours,
          offer_geography: offerGeo,
          offer_status: offerStatus,
          brand_id: brandId,
          ...eventProperties,
        },
      },
    ]);
  };

  React.useEffect(() => {
    if (!deviceId || !offerId) return;
    handleAmplitude("visit_offer_page", {
      offer_code: offer,
    });
  }, [deviceId, offerId, brandId]);

  React.useEffect(() => {
    if (title && offerCode && !isTracking && typeof ttq !== "undefined") {
      ttq.track("ViewContent", {
        contents: [
          {
            content_id: offerCode, // string. ID of the product. Example: "1077218".
            content_type: "product", // string. Either product or product_group.
            content_name: title, // string. The name of the page or product. Example: "shirt".
          },
        ],
      });
      setIsTracking(true);
    }
  }, [title, offerCode]);

  React.useEffect(() => {
    if (errorState === true) {
      setLoading(false);
    }
  }, [errorState]);

  React.useEffect(() => {
    if (
      imageState !== 1 &&
      slug !== null &&
      slug !== "inKind restaurants" &&
      mapSet === false
    ) {
      const map = document.getElementsByClassName("inkind-map")[0];
      slug !== "" ? map.setAttribute("data-scope", slug) : "";
      let script = document.createElement("script");
      script.src = "/assets/maps/initialization.js";
      script.type = "text/javascript";
      document.head.appendChild(script);
      setMapSet(true);
    }
  }, [slug]);

  const handlePost = (path, func, head, body) => {
    setLoading(true);
    let finalHead = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    if (!isEmpty(head)) {
      finalHead = {
        ...head,
        "X-InKind-Session": sessionId,
        "X-Inkind-Device": deviceId,
      };
    }
    let config = { method: "POST" };
    // if (!!head) {
    config.headers = finalHead;
    // }
    if (!!body) {
      config.body = body;
    }
    fetch(path, config)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        func(data);
      })
      .catch((err) => {
        setErrorState(true);
      });
  };

  const handleGet = (path, func) => {
    let config = { method: "GET" };
    config.headers = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    setLoading(true);
    fetch(path, config)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((response) => {
        func(response);
      })
      .catch((err) => {
        setErrorState(true);
        setErrorMessage(err.message);
      });
  };

  const handleFinal = (data) => {
    setLoading(false);
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    const isExistingUser = data.is_existing_user;
    let offerState = OfferState.newUserNoLongerAvailable;
    //No account
    if (!isExistingUser && err) {
      if (err.message === "Offer is not valid.") {
        offerState = OfferState.newUserNoLongerAvailable;
      } else if (
        err.message === `Oops, looks like you've already claimed this offer.`
      ) {
        offerState = OfferState.prevClaimed;
      } else if (
        err.message ===
        `Your unique offer code has already been claimed. If you are the one who claimed it, install the ${ikname} App to use it if you haven't already.`
      ) {
        offerState = OfferState.prevClaimed;
      }
      setDisplayState(offerState);
      return;
    }
    if (!isExistingUser && !err) {
      offerState = OfferState.noAccount;
      setDisplayState(offerState);
      return;
    }
    //Return user
    if (data.claimed && !err) {
      if (window._ik_on_offer_lead != null) {
        window._ik_on_offer_lead(
          "offer_claim",
          offerCode,
          numeral(value).value(),
        );
      }
      offerState = OfferState.success;
    } else if (data.claimed === true && data.is_existing_user === false) {
      offerState = OfferState.returnUserNewUserOnly;
    } else if (data.is_existing_user && data.is_existing_user === true) {
      offerState = OfferState.authentication;
      if (restrictNew === true && data.is_existing_user === true && err) {
        setErrorState(true);
        setErrorMessage(
          "This offer is only available to new members. But don't worry! You can still get a bonus when you add funds to your existing account:",
        );
      }
    }

    if (
      err &&
      err.message === `Oops, looks like you've already claimed this offer.`
    )
      offerState = OfferState.prevClaimed;
    if (
      err &&
      err.message === "Sorry, this offer is limited to new signups only."
    )
      offerState = OfferState.returnUserNewUserOnly;
    if (
      err &&
      err.message ===
        `Your unique offer code has already been claimed. If you are the one who claimed it, install the ${ikname} App to use it if you haven't already.`
    ) {
      offerState = OfferState.success;
      setErrorState(true);
    }
    if (err && err.message === "You cannot claim your own referral.")
      offerState = OfferState.claimOwnReferral;
    if (err && err.message === "Offer is not valid.")
      offerState = OfferState.returnUserNoLongerAvailable;
    if (err && err.message === "Invalid email or password.") {
      offerState = OfferState.authentication;
    }
    if (data.used && !data.claimed) offerState = OfferState.prevUsed;
    setDisplayState(offerState);
  };

  const handleClaimData = (data) => {
    let offerState = OfferState.emailInput;
    if (!!data.verified) {
      offerState;
    } else {
      setErrorState(true);
      offerState = OfferState.success;
    }
    setDisplayState(offerState);
    setLoading(false);
  };

  const handleOffer = (response) => {
    const err = !!response?.error ? InKindNetworkError(response.error) : null;
    let offerState = OfferState.newUserNoLongerAvailable;
    setLoading(false);
    if (!!err && !!response.tiers) {
      setTiers(response.tiers);
      setImageState(2);
      setErrorMessage(err.message);
      setErrorState(true);
      setDisplayState(offerState);
      return;
    } else if (!!err) {
      setShareUrl(response?.group?.share_url);
      setPpData(response?.group?.account_types[0].gcos);
      setImageState(0);
      setErrorMessage(err.message);
      setErrorState(true);
      offerState = OfferState.success;
      setDisplayState(offerState);
      return;
    }
    //set various states and variables based on offer data
    setOfferId(response.offer.id);
    setBrandId(response.offer.brand?.brand_id);
    setValue("$" + response.offer.amount);
    setShareUrl(response?.group?.share_url);
    setMinimumAmount("$" + response.offer.minimum_spend_amount);
    setMinimumSpend(response.offer.minimum_spend_required);
    setOfferType(response.offer.type);
    setIdValue(response.offer.id);
    setTitle(response.offer.name);
    setOfferStatus(response?.offer?.status);
    setOfferGeo(response?.offer?.geo_name);
    setDescription(
      // If show_offer_details is not configured, then do not evaluate the condition
      // If configured, it has to be true along with other conditions to show the offer details
      (offerAmplitudePresenter?.show_offer_details === null ||
        offerAmplitudePresenter?.show_offer_details) &&
        response.offer.type !== "ambassador_referral" &&
        response.offer.type !== "user_referral" &&
        !response.offer.is_active_in_campaign
        ? response.offer.details
        : "",
    );
    setSendType(response.offer.require_phone_to_claim ? "phone" : "email");
    setExpiration(response.offer.expiration);
    setRestrictNew(response.offer.restrict_to_new_users);
    setRedeemableDays(response?.offer?.redeemable_days);
    setLimitedHours(response?.offer?.has_limited_hours);
    setNextEligibleDate(response?.offer?.next_eligible_date);
    setRedeemableShortDays(response?.offer?.redeemable_days_short);
    setRestrictionText(response?.offer?.restriction_text);
    setRestrictionHighlights(response?.offer?.restriction_highligts);
    setDaysToRedeem(
      response?.offer?.expiration?.days
        ? response?.offer?.expiration?.days
        : null,
    );
    setVideoUrl(offerAmplitudePresenter?.video_url);
    if (response.offer?.group) {
      //handle brand images and pp data
      setSlug(response.offer.brand_slugs.join(","));

      if (response?.offer?.brand) {
        setBrand(response.offer.brand.name);
        response.offer.brand.branding?.hero_image?.value
          ? setGroupHero(
              cloudinaryPath +
                "c_fit,w_1920/" +
                response.offer.brand.branding.hero_image.value.replace(
                  /.png/i,
                  ".jpg",
                ),
            )
          : null;
        response.offer.brand.branding?.logo_wide?.dark_mode
          ? setGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.brand.branding.logo_wide.dark_mode,
            )
          : null;
        response.offer.brand.branding?.logo.dark_mode
          ? setDesktopGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.brand.branding.logo.dark_mode,
            )
          : null;
      } else {
        setBrand(response.offer.group.name);
        response.offer.group.branding?.hero_image?.value
          ? setGroupHero(
              cloudinaryPath +
                "c_fit,w_1920/" +
                response.offer.group.branding.hero_image.value.replace(
                  /.png/i,
                  ".jpg",
                ),
            )
          : null;
        response.offer.group.branding?.logo_wide?.dark_mode
          ? setGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.group.branding.logo_wide.dark_mode,
            )
          : null;
        response.offer.group.branding?.logo?.dark_mode
          ? setDesktopGroupLogo(
              cloudinaryPath +
                "c_fit,h_240/" +
                response.offer.group.branding.logo.dark_mode,
            )
          : null;
      }
      setImageState(1);
      setPpData(response?.offer?.group?.account_types[0]?.gcos);
    } else {
      setSlug("inKind restaurants");
      setTiers(response?.tiers ?? null);
      setImageState(2);
    }

    if (!response.offer.additional_code_required) {
      setDisplayState(OfferState.emailInput);
      setLoading(false);
    } else if (!claimCode || claimCode.length === 0) {
      //if there needs to be one, and it needs to be entered manually
      //setSlug("");
      setDisplayState(OfferState.claimCode);
      setLoading(false);
    } else {
      //if there's one in the URL that just needs to be checked
      handlePost(
        "/api/v5/offers/verify_claim_code?claim_code=" +
          encodeURIComponent(claimCode) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleClaimData,
      );
    }
  };

  const handleEmailClaim = () => {
    if (!offerResponse.offer) {
      setImageState(0);
      const isOfferExpired = offerResponse.error === "Offer is not valid.";
      if (!isOfferExpired) setErrorState(true);
      const state = isOfferExpired
        ? OfferState.returnUserNoLongerAvailable
        : OfferState.success;
      setDisplayState(state);
      return;
    }
    handleOffer(offerResponse);
    if (claimResponse.claimed && !claimResponse.error) {
      setDisplayState(OfferState.emailClaimSuccess);
    } else if (
      claimResponse.error ===
      `Oops, looks like you've already claimed this offer.`
    ) {
      setDisplayState(OfferState.prevClaimed);
    } else {
      setErrorState(true);
      setDisplayState(OfferState.success);
    }
  };

  const handleInitDisplay = () => {
    if (claimResponse || offerResponse) {
      handleEmailClaim();
    } else if (!offerCode || offerCode.length < 1) {
      setDisplayState(OfferState.offerCode);
      setImageState(0);
      setLoading(false);
    } else {
      handleGet("/api/v5/offers/" + offerCode, handleOffer);
    }
  };

  const handleCodeSubmit = (code) => {
    let emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const stripped_code = code.match(emailReg)
      ? code.toUpperCase()
      : String(code).replace(/[\W_]+/g, "");
    if (displayState === OfferState.offerCode) {
      setOfferCode(stripped_code);
      handleGet("/api/v5/offers/" + stripped_code, handleOffer);
    } else if (displayState === OfferState.claimCode) {
      setClaimCode(hasHashedClaimCode ? code : stripped_code);
      handlePost(
        "/api/v5/offers/verify_claim_code?claim_code=" +
          encodeURIComponent(hasHashedClaimCode ? code : stripped_code) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleClaimData,
      );
    }
  };

  const handleVerif = (data) => {
    setDisplayState(OfferState.smsVerification);
    setLoading(false);
    setTokenUse(data.token);
  };

  const verifySmsCode = (verification_code) => {
    if (codeSubmitting) return;

    setCodeSubmitting(true);
    handleShowError(false);
    handlePost(
      "/api/v5/offers/verify_sms_code?token=" +
        encodeURIComponent(tokenUse) +
        "&sms_verification_code=" +
        encodeURIComponent(verification_code) +
        "&id=" +
        encodeURIComponent(offerCode),
      handleSmsResponse,
    );
  };

  const checkVerificationStatus = async () => {
    const headers = {
      "X-InKind-Session": sessionId,
      "X-Inkind-Device": deviceId,
    };
    await fetch(
      "/api/v5/offers/check_if_verified?token=" +
        encodeURIComponent(tokenUse) +
        "&id=" +
        encodeURIComponent(offerCode),
      { method: "POST", headers: headers },
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.token != null) {
          claimOffer(r);
        }
      });
  };

  const handleSmsResponse = (data) => {
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    setLoading(false);
    setCodeSubmitting(false);
    if (!data?.token) {
      handleShowError(true);
      const inputs = document.querySelectorAll("#otp > *");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].value = "";
      }
      inputs[0].focus();
    } else if (
      data.is_user === false ||
      (data.is_user === true && data.user?.token)
    ) {
      claimOffer(data);
    } else if (data.is_user === true) {
      setDisplayState(OfferState.authentication);
      if (restrictNew === true && err) {
        setErrorState(true);
        setErrorMessage(
          "This offer is only available to new members. But don't worry! You can still get a bonus when you add funds to your existing account:",
        );
      }
    } else if (err) {
      setErrorState(true);
      setErrorMessage(err.message);
    }
  };

  const claimOffer = (data) => {
    const err = !!data?.error ? InKindNetworkError(data.error) : null;
    if (data?.error === "Invalid email or password") {
      handleShowError(true);
      setErrorMessage(data.error);
    }
    if (data?.errors && data?.errors[0].message) {
      handleShowError(true);
      setErrorMessage(data?.errors[0].message);
    }
    if (!data) {
      handleShowError(true);
      setLoading(false);
      document.querySelector("input[name='Password']").value = "";
      return;
    }
    if (!!data?.token) {
      setTokenUse(data.token);
    }
    const token = data?.token?.length > 0 ? data.token : tokenUse;
    let url = !!claimCode
      ? "/api/v5/offers/claim_offer?token=" +
        encodeURIComponent(token) +
        "&id=" +
        encodeURIComponent(offerCode) +
        "&claim_code=" +
        encodeURIComponent(claimCode) +
        "&sms_opt_in=" +
        smsOptIn
      : "/api/v5/offers/claim_offer?token=" +
        encodeURIComponent(token) +
        "&id=" +
        encodeURIComponent(offerCode) +
        "&sms_opt_in=" +
        smsOptIn;
    if (!!data?.user?.token) {
      let thisSmsOptIn = {
        sms_opt_in: smsOptIn,
      };
      let bodyUse = JSON.stringify(thisSmsOptIn);
      handlePost(url, handleFinal, {
        Authorization: "Bearer " + data.user.token,
      });
    } else {
      handlePost(url, handleFinal);
    }
  };

  const scrollTo = (where) => {
    document.querySelector(where).scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    if (sendType === "phone") {
      let num = e.target[0]?.value || phoneNum;
      if (title && offerCode && typeof ttq !== "undefined") {
        ttq.track("ClickButton", {
          contents: [
            {
              content_id: offerCode, // string. ID of the product. Example: "1077218".
              content_type: "product", // string. Either product or product_group.
              content_name: title, // string. The name of the page or product. Example: "shirt".
            },
          ],
        });
      }
      handleAmplitude("submit_phone_number_for_verification", {
        phone_number: num,
        offer_code: offer,
      });
      if (
        offerAmplitudePresenter &&
        offerAmplitudePresenter.experiment &&
        offerAmplitudePresenter.experiment.flag_key &&
        offerAmplitudePresenter.experiment.variant
      )
        handleAmplitudePost([
          {
            subscriber_id: null,
            device_id: deviceId,
            event_name: "$exposure",
            time: Date.now(),
            event_properties: {
              flag_key: offerAmplitudePresenter.experiment.flag_key,
              variant: offerAmplitudePresenter.experiment.variant,
            },
            custom_fields: {
              property_groups: [],
              user_properties: [],
              event_properties: [],
            },
          },
        ]);
      handlePost(
        "/api/v5/offers/send_sms_verification_code?phone_number=" +
          encodeURIComponent(num) +
          "&id=" +
          encodeURIComponent(offerCode),
        handleVerif,
      );
      if (num !== phoneNum) {
        setPhoneNum(num);
      }
    } else if (sendType === "email") {
      handleAmplitude("enter_login_email", { email: e.target[0].value });
      handlePost(
        "/api/v5/offers/validate_email?email=" +
          encodeURIComponent(e.target[0].value.toLowerCase().trim()) +
          "&id=" +
          encodeURIComponent(offerCode),
        claimOffer,
      );
      setEmail(e.target[0].value.toLowerCase().trim());
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();

    handleAmplitude("enter_login_password", {
      email: e.target[0].value.toLowerCase().trim(),
    });
    handleShowError(false);
    let thisUser = {
      user: {
        email: e.target[0].value.toLowerCase().trim(),
        password: e.target[1].value,
      },
    };
    let bodyUse = JSON.stringify(thisUser);
    // let headersUse = new Headers({ "content-type": "application/json" });
    handlePost(
      "/api/v5/login",
      claimOffer,
      { "content-type": "application/json" },
      bodyUse,
    );
  };

  const getInkindBackgroundImage = () => {
    if (imageState === 0) return offerCodeBg;
    if (imageState === 2 && isMobile && coBrandedOfferClaim) return offerBg;
    if (imageState === 2 && !isMobile) return offerBg;
    return "";
  };

  const getNoshdBackgroundImage = () => {
    if (imageState === 0) return offerCodeBg;

    if (imageState === 2) {
      if (isMobile) {
        return coBrandedOfferClaim ? offerBg : mobilePassI;
      }
      return offerBg;
    }

    return groupHero || "";
  };

  const leftLogoEnterpriseStyle = () => {
    const ikName = ikname == "inKind" ? "inkind" : "noshd";

    return `left-${ikName}-logo`;
  };

  const rightCoBrandedLogoStyle = () => {
    const ikName = ikname == "inKind" ? "inkind" : "noshd";

    return coBrandedLogoLandscape
      ? `right-co-branded-${ikName}-landscape-logo`
      : `right-co-branded-${ikName}-portrait-logo`;
  };

  const renderLogoSection = (isPassLogo) => {
    const logoClass = isPassLogo
      ? "header-ik-logo pass-logo"
      : "header-ik-logo";

    const verticalLineClass =
      ikname == "noshd"
        ? "vertical-line-noshd-logo"
        : "vertical-line-inkind-logo";

    if (coBrandedOfferClaim) {
      return (
        <>
          <img
            src={inkindLogo}
            className={`${logoClass} ${leftLogoEnterpriseStyle()}`}
            alt="Offer Logo"
          />
          <span className={`${verticalLineClass}`}></span>
          <img
            src={coBrandedLogoUrl}
            className={`${rightCoBrandedLogoStyle()}`}
            alt="Co Branded Offer Logo"
          />
        </>
      );
    }
    return <img src={inkindLogo} className={logoClass} alt="Offer Logo" />;
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handlePrev = () => {
    setSelectedIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setSelectedIndex((prevIndex) => prevIndex + 1);
  };

  React.useEffect(() => {
    const numOfSlides = slug !== "inKind restaurants" ? 5 : 4;
    if (selectedIndex > numOfSlides - 1) {
      setSelectedIndex(selectedIndex - numOfSlides);
    } else if (selectedIndex < 0) {
      setSelectedIndex(selectedIndex + numOfSlides);
    }
  }, [selectedIndex]);

  const getInkindLogoClass = () => {
    if (slug === "inKind restaurants" || slug === null) {
      if (ocpSlidesExperiment) {
        return "ocp-header-img";
      } else {
        return selectedIndex === 0
          ? "header-brand-img-container"
          : "ocp-header-img";
      }
    }
    if (selectedIndex === 0) {
      return ocpSlidesExperiment && imageState === 1
        ? "header-brand-img-container"
        : "ocp-header-img";
    }
    return "ocp-header-img";
  };

  let logoSection = null;
  if (
    imageState === 1 &&
    selectedIndex !== 0 &&
    slug !== "inKind restaurants"
  ) {
    logoSection = renderLogoSection(true);
  } else if (imageState === 1) {
    logoSection = (
      <img src={isMobile ? groupLogo : desktopGroupLogo} alt="Logo" />
    );
  }

  const getFlexDirection = () => {
    if (coBrandedOfferClaim) {
      return "row";
    }

    if (imageState === 2 || (imageState === 1 && selectedIndex !== 0)) {
      return "column";
    } else {
      return "row";
    }
  };

  return (
    <div
      className={`offers-main-container ${
        imageState === 2 ? "offers-pass-container" : ""
      }`}
    >
      <div className="offers-main-content">
        <main
          className={`offers-main ${ocpSlidesExperiment ? "ocp-offers-main" : ""}`}
        >
          {imageState !== 1 && ikname === "inKind" && (
            <div
              className="offers-brand-main-around"
              style={{
                backgroundImage:
                  !ocpSlidesExperiment ||
                  (ocpSlidesExperiment && (displayState === 0 || !slug))
                    ? `url(${getInkindBackgroundImage()})`
                    : "none",
              }}
            >
              {ocpSlidesExperiment && slug === "inKind restaurants" && (
                <>
                  <Carousel
                    autoPlay={phoneNum}
                    interval={2700}
                    infiniteLoop={true}
                    showIndicators={false}
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    selectedItem={selectedIndex}
                    swipeable={true}
                    dynamicHeight={true}
                    emulateTouch={true}
                    transitionTime={500}
                    onChange={(e) => setSelectedIndex(e)}
                    stopOnHover={false}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        className="carousel-image"
                        src={offer1}
                        alt={"The only 20% back dining app"}
                      />
                      <span className="carousel-text-container">
                        <h1 className="carousel-text">THE ONLY</h1>
                        <h1 className="carousel-text">20% BACK</h1>
                        <span className="carousel-subtext">Dining app</span>
                      </span>
                    </div>

                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        className="carousel-image"
                        src={offer2}
                        alt={"Join over 2 Million Foodies"}
                      />
                      <span className="carousel-text-container slide-2-content">
                        <p
                          className="carousel-subtext"
                          style={{ fontFamily: "Rethink-Sans-Bold" }}
                        >
                          Join over
                        </p>
                        <span className="carousel-text">2 MILLION FOODIES</span>
                        <div className="star-rating-container">
                          {Array(5)
                            .fill()
                            .map((rating, index) => (
                              <img
                                key={`star-${index + 1}`}
                                src={starRating}
                                alt="Star Rating"
                                className="star"
                              />
                            ))}
                        </div>
                        <span className="star-rating-text">
                          4.8 App store rating
                        </span>
                      </span>
                    </div>

                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        className="carousel-image"
                        src={offer3}
                        alt={"Dine like a local"}
                      />
                      <span className="carousel-text-container slide-3-content">
                        <p className="carousel-text">DINE LIKE A LOCAL</p>
                        <span className="carousel-subtext">
                          Wherever you go, inKind has thousands of restaurants,
                          bars, and cafés
                        </span>
                      </span>
                    </div>

                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        className="carousel-image"
                        src={offer4}
                        alt={"Tastebud approved"}
                      />
                      <span className="carousel-text-container slide-4-content">
                        <p className="carousel-text">Tastebud approved</p>
                        <span className="carousel-subtext slide-4-text">
                          Discover hand-picked,
                          <br />
                          highly-rated restaurants
                        </span>
                      </span>
                    </div>
                  </Carousel>
                  <div
                    className={getInkindLogoClass()}
                    style={
                      coBrandedOfferClaim
                        ? { flexDirection: "row" }
                        : {
                            flexDirection:
                              imageState === 2 ||
                              (imageState === 1 && selectedIndex !== 0)
                                ? "column"
                                : "row",
                          }
                    }
                  >
                    {logoSection}
                    {imageState === 0 ? renderLogoSection(false) : <></>}
                    {imageState === 2 ? renderLogoSection(true) : <></>}
                  </div>
                  <div className="carousel-controls">
                    <button
                      onClick={handlePrev}
                      style={{ paddingRight: "10px" }}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" ||
                          e.key === " " ||
                          e.key === "ArrowLeft"
                        ) {
                          handlePrev();
                        }
                      }}
                    >
                      <img
                        className="previousSlide-icon"
                        src={previousSlide}
                        alt={"Previous Slide"}
                      />
                    </button>
                    <button
                      onClick={handleNext}
                      onKeyDown={(e) => {
                        if (
                          e.key === "Enter" ||
                          e.key === " " ||
                          e.key === "ArrowRight"
                        ) {
                          handleNext();
                        }
                      }}
                    >
                      <img
                        className="nextSlide-icon"
                        src={nextSlide}
                        alt={"Next Slide"}
                      />
                    </button>
                  </div>
                </>
              )}
              {!coBrandedOfferClaim && !ocpSlidesExperiment && (
                <video
                  id="video"
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                  controls={false}
                  controlsList="nofullscreen nodownload noremoteplayback"
                  src={videoUrl}
                  type="video/mp4"
                  className="bg-video"
                />
              )}
              {(slug === "inKind restaurants" || slug === null) &&
                !ocpSlidesExperiment &&
                selectedIndex === 0 && (
                  <div
                    className={getInkindLogoClass()}
                    style={
                      coBrandedOfferClaim
                        ? { flexDirection: "row" }
                        : {
                            flexDirection:
                              imageState === 2 ||
                              (imageState === 1 && selectedIndex !== 0)
                                ? "column"
                                : "row",
                          }
                    }
                  >
                    {logoSection}
                    {imageState === 0 ? renderLogoSection(false) : <></>}
                    {imageState === 2 ? renderLogoSection(true) : <></>}
                  </div>
                )}
              {coBrandedOfferClaim && (
                <div className="powered-by">
                  Courtesy of{" "}
                  <img
                    className="co-branded-ik-logo-white"
                    src={ikLogoWhite}
                    alt="inkind"
                  />
                </div>
              )}
            </div>
          )}
          {imageState === 1 && ikname === "inKind" && ocpSlidesExperiment && (
            <div className="offers-brand-main-around">
              {ocpSlidesExperiment && slug !== "inKind restaurants" && (
                <Carousel
                  autoPlay={phoneNum}
                  interval={2700}
                  infiniteLoop={true}
                  showIndicators={false}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  selectedItem={selectedIndex}
                  swipeable={true}
                  dynamicHeight={true}
                  emulateTouch={true}
                  transitionTime={500}
                  onChange={(e) => setSelectedIndex(e)}
                  stopOnHover={false}
                >
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={getNoshdBackgroundImage()}
                      className="carousel-image"
                      alt="Background"
                    />
                    <div
                      className="header-img-container-carousel"
                      style={
                        slug !== "inKind restaurants" &&
                        slug !== null &&
                        selectedIndex === 1 &&
                        ocpSlidesExperiment
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      {imageState === 1 ? (
                        <img
                          className="header-img"
                          src={isMobile ? groupLogo : desktopGroupLogo}
                          alt="header-logo  "
                        />
                      ) : (
                        <></>
                      )}
                      {imageState === 0 ? renderLogoSection(false) : null}
                      {imageState === 2 ? renderLogoSection(true) : null}
                    </div>

                    <div className="powered-by">
                      Courtesy of{" "}
                      <img
                        className="co-branded-ik-logo-white"
                        src={ikLogoWhite}
                        alt="inkind"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      className="carousel-image"
                      src={offer1}
                      alt={"The only 20% back dining app"}
                    />
                    <span className="carousel-text-container">
                      <h1 className="carousel-text">THE ONLY</h1>
                      <h1 className="carousel-text">20% BACK</h1>
                      <span className="carousel-subtext">Dining app</span>
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      className="carousel-image"
                      src={offer2}
                      alt={"Join over 2 Million Foodies"}
                    />
                    <span className="carousel-text-container">
                      <p
                        className="carousel-subtext"
                        style={{ fontFamily: "Rethink-Sans-Bold" }}
                      >
                        Join over
                      </p>
                      <span className="carousel-text">2 MILLION FOODIES</span>
                      <div className="star-rating-container">
                        {Array(5)
                          .fill()
                          .map((rating, index) => (
                            <img
                              key={`star-${index + 1}`}
                              src={starRating}
                              alt="Star Rating"
                              className="star"
                            />
                          ))}
                      </div>
                      <span className="star-rating-text">
                        4.8 App store rating
                      </span>
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      className="carousel-image"
                      src={offer3}
                      alt={"Dine like a local"}
                    />
                    <span className="carousel-text-container slide-3-content">
                      <p className="carousel-text">DINE LIKE A LOCAL</p>
                      <span className="carousel-subtext">
                        Wherever you go, inKind has thousands of restaurants,
                        bars, and cafés
                      </span>
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      className="carousel-image"
                      src={offer4}
                      alt={"Tastebud approved"}
                    />
                    <span className="carousel-text-container slide-4-content">
                      <p className="carousel-text">Tastebud approved</p>
                      <span className="carousel-subtext">
                        Discover hand-picked,
                        <br /> highly-rated restaurants
                      </span>
                    </span>
                  </div>
                </Carousel>
              )}
              <div
                className={getInkindLogoClass()}
                style={
                  slug !== "inKind restaurants" &&
                  slug !== null &&
                  selectedIndex === 0 &&
                  imageState === 1 &&
                  ocpSlidesExperiment
                    ? { display: "none" }
                    : {
                        flexDirection: getFlexDirection(),
                      }
                }
              >
                {logoSection}
                {imageState === 0 ? renderLogoSection(false) : <></>}
                {imageState === 2 ? renderLogoSection(true) : <></>}
              </div>
              {ocpSlidesExperiment && (
                <div className="carousel-controls">
                  <button
                    onClick={handlePrev}
                    style={{ paddingRight: "10px" }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" ||
                        e.key === " " ||
                        e.key === "ArrowLeft"
                      ) {
                        handlePrev();
                      }
                    }}
                  >
                    <img
                      className="previousSlide-icon"
                      src={previousSlide}
                      alt={"Previous Slide"}
                    />
                  </button>
                  <button
                    onClick={handleNext}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" ||
                        e.key === " " ||
                        e.key === "ArrowRight"
                      ) {
                        handleNext();
                      }
                    }}
                  >
                    <img
                      className="nextSlide-icon"
                      src={nextSlide}
                      alt={"Next Slide"}
                    />
                  </button>
                </div>
              )}
              {coBrandedOfferClaim && (
                <div className="powered-by">
                  Courtesy of{" "}
                  <img
                    className="co-branded-ik-logo-white"
                    src={ikLogoWhite}
                    alt="inkind"
                  />
                </div>
              )}
            </div>
          )}
          {(imageState !== 1 && ikname === "noshd") ||
          (!ocpSlidesExperiment && imageState === 1) ? (
            <div
              className="offers-main-around"
              style={{ backgroundImage: `url(${getNoshdBackgroundImage()})` }}
            >
              <div
                className="header-img-container"
                style={
                  coBrandedOfferClaim
                    ? { flexDirection: "row" }
                    : { flexDirection: imageState === 2 ? "column" : "row" }
                }
              >
                {imageState === 1 ? (
                  <img src={isMobile ? groupLogo : desktopGroupLogo} />
                ) : (
                  <></>
                )}
                {imageState === 0 ? renderLogoSection(false) : <></>}
                {imageState === 2 ? renderLogoSection(true) : <></>}
              </div>
              <div className="powered-by">
                Courtesy of{" "}
                <img
                  className={
                    coBrandedOfferClaim ? "co-branded-ik-logo-white" : ""
                  }
                  src={coBrandedOfferClaim ? ikLogoWhite : ikLogo}
                />
              </div>
            </div>
          ) : null}
          <div
            className={`main-container ${ocpSlidesExperiment ? "ocp-slides-form" : ""}`}
          >
            <div
              className="grid-container"
              style={{
                marginTop: errorState === true && "0px",
                marginBottom: errorState === true && "0px",
              }}
            >
              <div
                className={
                  displayState === OfferState.altVerification &&
                  errorState === false
                    ? "hide-if-on-mobile"
                    : errorState === true
                      ? "hide-if-error"
                      : displayState === 4
                        ? "welcome-container-center"
                        : displayState >= 1 && displayState < OfferState.success
                          ? "welcome-container"
                          : ""
                }
              >
                {!errorState &&
                  (displayState === OfferState.emailInput ||
                    displayState === OfferState.smsVerification ||
                    displayState === OfferState.emailClaimSuccess) && (
                    <WelcomeBonus
                      title={title}
                      value={value}
                      displayState={displayState}
                      description={description}
                      restrictionText={restrictionText}
                      restrictionHighlights={restrictionHighlights}
                    />
                  )}
              </div>

              {!errorState && displayState === OfferState.emailInput && (
                <ClaimBox
                  offerAmplitudePresenter={offerAmplitudePresenter}
                  value={value}
                  email={email}
                  phoneNum={phoneNum}
                  sendType={sendType}
                  handleContactSubmit={handleContactSubmit}
                  stateToSet={sendType === "email" ? setEmail : setPhoneNum}
                  restrictNew={restrictNew}
                  setSmsOptIn={setSmsOptIn}
                  expiration={expiration}
                  redeemableShortDays={redeemableShortDays}
                  limitedHours={limitedHours}
                  inkindStateRequiredEmails={inkindStateRequiredEmails}
                  inkindStateRequiredEmailsDisplay={
                    inkindStateRequiredEmailsDisplay
                  }
                  loading={loading}
                  inkindStatePrivacyUrl={inkindStatePrivacyUrl}
                  inkindStateTermsUrl={inkindStateTermsUrl}
                  ikname={ikname}
                  handleAmplitude={handleAmplitude}
                  offerType={offerType}
                />
              )}

              {!errorState && displayState === OfferState.smsVerification && (
                <VerificationBox
                  value={value}
                  phoneNum={phoneNum}
                  setDisplayState={setDisplayState}
                  setSendType={setSendType}
                  verifySmsCode={verifySmsCode}
                  codeSubmitting={codeSubmitting}
                  handleContactSubmit={handleContactSubmit}
                  showError={showError}
                  handleShowError={handleShowError}
                  isPass={imageState === 2 ? true : false}
                  loading={loading}
                  handleAmplitude={handleAmplitude}
                  setLoading={setLoading}
                />
              )}

              {!errorState && displayState === OfferState.altVerification && (
                <AltVerification
                  checkVerificationStatus={checkVerificationStatus}
                  smsNumber={smsNumber}
                  goBack={() => setDisplayState(OfferState.smsVerification)}
                />
              )}

              {(errorState || !errorState) &&
                (displayState === OfferState.success ||
                  displayState === OfferState.prevClaimed ||
                  displayState === OfferState.prevUsed) && (
                  <WelcomeBack
                    value={value}
                    displayState={displayState}
                    expirationDays={expiration.days}
                    ikname={ikname}
                    errorState={errorState}
                    inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                    inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                    inkindStateDomain={inkindStateDomain}
                    handleAmplitude={handleAmplitude}
                  />
                )}
              {errorState &&
                displayState === OfferState.returnUserNoLongerAvailable && (
                  <ReturnUserNoLongerAvailable
                    expirationDays={expiration.days}
                    isNewUserOnly={
                      displayState === OfferState.returnUserNewUserOnly
                    }
                    tiers={tiers}
                    ikname={ikname}
                  />
                )}
              {errorState &&
                displayState === OfferState.returnUserNewUserOnly && (
                  <NewUserNoLongerAvailable
                    ikname={ikname}
                    enterprise={enterprise}
                    inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                    inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                    handleAmplitude={handleAmplitude}
                    isNewUserOnly={true}
                  />
                )}
              {(errorState || !errorState) &&
                (displayState === OfferState.returnUserNoLongerAvailable ||
                  displayState === OfferState.newUserNoLongerAvailable) && (
                  <NewUserNoLongerAvailable
                    ikname={ikname}
                    enterprise={enterprise}
                    inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                    inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                    handleAmplitude={handleAmplitude}
                  />
                )}
              {!errorState && displayState === OfferState.noAccount && (
                <NoAccount
                  offerAmplitudePresenter={offerAmplitudePresenter}
                  value={value}
                  enterprise={enterprise}
                  ikname={ikname}
                  inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                  inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                  handleAmplitude={handleAmplitude}
                  inkindStateDomain={inkindStateDomain}
                />
              )}
              {!errorState && displayState === OfferState.claimOwnReferral && (
                <WelcomeBack
                  value={value}
                  displayState={displayState}
                  expirationDays={expiration.days}
                  ikname={ikname}
                  errorState={errorState}
                  inkindStateAppStoreUrl={inkindStateAppStoreUrl}
                  inkindStatePlayStoreUrl={inkindStatePlayStoreUrl}
                  inkindStateDomain={inkindStateDomain}
                  handleAmplitude={handleAmplitude}
                />
              )}

              {!errorState && displayState === OfferState.authentication && (
                <SignIn
                  email={email}
                  userPassword={userPassword}
                  setUserPassword={setUserPassword}
                  handleLogin={handleLogin}
                  sendType={sendType}
                  showError={showError}
                  errorMessage={errorMessage}
                  expirationDays={expiration.days}
                  handleShowError={handleShowError}
                  ikname={ikname}
                  inkindStateRequiredEmails={inkindStateRequiredEmails}
                  inkindStateRequiredEmailsDisplay={
                    inkindStateRequiredEmailsDisplay
                  }
                  loading={loading}
                  handleAmplitude={handleAmplitude}
                />
              )}

              {!errorState && displayState === OfferState.offerCode && (
                <OfferCode
                  title={"Enter claim code"}
                  handleCodeSubmit={handleCodeSubmit}
                  placeholder={"Claim code"}
                  loading={loading}
                />
              )}

              {!errorState && displayState === OfferState.claimCode && (
                <OfferCode
                  title={"Enter your offer code"}
                  handleCodeSubmit={handleCodeSubmit}
                  placeholder={"Code"}
                  loading={loading}
                />
              )}
              {!errorState && displayState === OfferState.emailClaimSuccess && (
                <EmailClaimSuccess
                  ikname={ikname}
                  inkindStateDomain={inkindStateDomain}
                  handleAmplitude={handleAmplitude}
                  value={value}
                  userEmail={userEmail}
                />
              )}
            </div>
            <div
              className="map-show-container"
              style={{
                display:
                  !!slug &&
                  !isMobile &&
                  imageState !== 1 &&
                  displayState !== OfferState.emailClaimSuccess
                    ? "flex"
                    : "none",
              }}
            >
              <a
                className="map-show"
                onClick={(e) => {
                  scrollTo("#offers-map-section");
                  handleAmplitude("click_explore_map", {
                    phone_number: phoneNum,
                  });
                }}
              >
                Explore locations
                <div
                  style={{
                    width: "100%",
                    height: "23px",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "12px",
                  }}
                >
                  <img
                    src={downArrow}
                    alt="Scroll down"
                    className="down-arrow-offers bounce"
                  />
                </div>
              </a>
            </div>
          </div>
        </main>
      </div>

      {!!slug &&
        slug !== "inKind restaurants" &&
        imageState !== 1 &&
        displayState !== OfferState.emailClaimSuccess && (
          <div className="map-box map-box-dark" id="offers-map-section">
            <div
              className="inkind-map"
              data-headline-class="inkind-map-header"
              data-color="dark"
              data-list-scroll="false"
              data-tiles="true"
              data-max-height="600px"
              data-click-mode="modal"
              data-env={window.location.origin}
              data-offers="true"
            ></div>
          </div>
        )}
      {!!slug &&
        slug === "inKind restaurants" &&
        imageState !== 1 &&
        displayState !== OfferState.emailClaimSuccess && (
          <div className="map-box map-box-dark" id="offers-map-section">
            <div className="pass-map-header">
              {locationCount}+ Restaurants, Bars & Cafés
            </div>
            <iframe
              src="/embed/map?pass=true&colorMode=black"
              className="inkind-map"
              style={{
                width: "100%",
                height: "720px",
                maxHeight: "90vh",
                margin: "0 auto",
                display: "block",
              }}
            />
          </div>
        )}
    </div>
  );
};

export const OfferState = {
  offerCode: 0,
  claimCode: 1,
  emailInput: 2,

  smsVerification: 3,
  altVerification: 6,

  authentication: 4,
  success: 5,
  noAccount: 7,
  prevClaimed: 8,
  prevUsed: 9,
  returnUserNoLongerAvailable: 10,
  returnUserNewUserOnly: 11,
  newUserNoLongerAvailable: 12,
  claimOwnReferral: 13,
  emailClaimSuccess: 14,
};

export default withEventsLogger(App);
