import React, { useEffect, useState } from "react";
import { Splash } from "./Splash";

interface CheckNotFoundProps {
  logoSrc?: string;
  returnUrl?: string;
}

const CheckNotFound: React.FC<CheckNotFoundProps> = ({
  logoSrc,
  returnUrl,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (!returnUrl) return;

    const timeoutId = setTimeout(() => {
      window.location.replace(returnUrl);
    }, 3500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [returnUrl]);

  return (
    <>
      <Splash logoSrc={logoSrc} />
      <div
        className={`check-not-found ${!isOpen ? "check-not-found--hidden" : ""}`}
      >
        <div className="check-not-found__image" />
        <h2 className="check-not-found__title">Check not found</h2>
        <p className="check-not-found__message">
          {returnUrl
            ? "We couldn't load your bill. Returning to order..."
            : "Your specific ticket couldn't be found. Please try again or ask your server for help closing out."}
        </p>
        <button
          className="check-not-found__button"
          onClick={() => setIsOpen(false)}
        >
          Done
        </button>
      </div>
    </>
  );
};

export default CheckNotFound;
