import React, { Fragment, useEffect, useMemo, useState } from "react";
import { ProjectData } from "./type";
import CloseIcon from "./images/close-icon-white.svg";
import OneRewardIcon from "./images/one-reward-icon.svg";
import TwoRewardsIcon from "./images/two-rewards-icon.svg";
import { FormikRadioButton } from "../../ui/atoms/formik-radio-button";
import { addIneligibleReasons } from "./ApplyRewards";

const formatDateToMMDDYYYY = (dateString: string | null) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-");
  const shortYear = year.slice(-2);
  return `${month}/${day}/${shortYear}`;
};

const getInkindWalletObj = (projectData: ProjectData) => {
  const inKindWalletObj = {};
  if (projectData?.credit_balance)
    inKindWalletObj["House Account"] = projectData?.credit_balance?.toFixed(2);
  if (projectData?.ik_pass_balance)
    inKindWalletObj["inKind Wallet"] = projectData?.ik_pass_balance?.toFixed(2);
  return inKindWalletObj;
};

interface EditSavingsProps {
  projectData: ProjectData;
  showEditSavings: boolean;
  hideEditSavings: () => void;
  billTotal: number;
  availableBalance: number;
}

export const EditSavings: React.FC<EditSavingsProps> = ({
  projectData,
  showEditSavings,
  hideEditSavings,
  billTotal,
  availableBalance,
}) => {
  const [shakingOfferId, setShakingOfferId] = useState<number | null>(null);

  const validatedOffers = useMemo(
    () => addIneligibleReasons(projectData?.offers || [], billTotal),
    [projectData, billTotal],
  );

  const inkindWalletObj = getInkindWalletObj(projectData);

  const handleIneligibleOfferClick = (eligible: boolean, offerId: number) => {
    if (!eligible) {
      setShakingOfferId(offerId);
    }
  };

  useEffect(() => {
    if (shakingOfferId !== null) {
      const timer = setTimeout(() => {
        setShakingOfferId(null);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [shakingOfferId]);

  return (
    <div className={`edit-savings-wrapper ${showEditSavings ? "show" : ""}`}>
      <div className="edit-savings">
        <div className="edit-savings-content">
          <div className="edit-savings__header">
            <h2 className="edit-savings__title">Edit Savings</h2>
            <button
              className="edit-savings__close-button"
              onClick={hideEditSavings}
              type="button"
            >
              <img src={CloseIcon} alt="close icon" />
            </button>
          </div>
          <div className="rewards">
            <div className="rewards-title">Rewards</div>
            <div className="rewards-sub-title">
              Only one reward can be applied to your check
            </div>
            <ul className="rewards-options">
              {validatedOffers.map((offer) => {
                const eligible = !offer?.ineligableReason;
                const displayOfferAmount =
                  offer.claim_details.balance > billTotal
                    ? billTotal
                    : offer.claim_details.balance;
                return (
                  <Fragment key={offer.id}>
                    <li
                      className="rewards-option"
                      onClick={() =>
                        handleIneligibleOfferClick(eligible, offer.id)
                      }
                    >
                      <FormikRadioButton
                        name="offer_id"
                        value={`${offer.claim_details.offer_claim_id}`}
                        label={
                          <div className="offer-left">
                            <span>{offer.name}</span>
                            {offer.restriction_text && (
                              <span
                                className={`offer-sub-text ${eligible ? "" : "ineligible"}`}
                              >
                                {offer.restriction_text}
                              </span>
                            )}
                          </div>
                        }
                        disabled={!eligible}
                      />
                      <div className="offer-right">
                        <span className="offer-amount">
                          - ${displayOfferAmount.toFixed(2)}
                        </span>
                        {(!eligible || !!offer.claim_details.expires_at) && (
                          <span
                            className={`offer-sub-text ${eligible ? "" : "ineligible"} ${shakingOfferId === offer.id ? "shake" : ""}`}
                          >
                            {!eligible
                              ? offer?.ineligableReason
                              : `Exp. ${formatDateToMMDDYYYY(offer.claim_details.expires_at)}`}
                          </span>
                        )}
                      </div>
                    </li>
                    <div className="divider" />
                  </Fragment>
                );
              })}
              <li className="rewards-option">
                <FormikRadioButton
                  name="offer_id"
                  value={""}
                  label="Don’t apply an offer"
                />
              </li>
            </ul>
          </div>
          {availableBalance > 0 && (
            <div className="inkind-wallet">
              <div className="inkind-wallet-title">inKind Wallet</div>
              <ul className="inkind-wallet-options">
                <li className="inkind-wallet-option">
                  <div className="inkind-wallet-option-top edit-savings-row">
                    <FormikRadioButton
                      name="inkind_wallet"
                      value="apply"
                      label="Available Balance"
                    />
                    <span>${availableBalance.toFixed(2)}</span>
                  </div>
                  <div className="inkind-wallet-option-bottom">
                    {Object.entries(inkindWalletObj).map(
                      ([key, value], index) => {
                        return (
                          <div className="edit-savings-row" key={key}>
                            <div className="inkind-wallet-option-bottom-left">
                              <img
                                src={
                                  index === 0 ? OneRewardIcon : TwoRewardsIcon
                                }
                                alt="inkind wallet icon"
                              />
                              <span>{key}</span>
                            </div>
                            <div className="inkind-wallet-option-bottom-right">
                              {`$${value}`}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </li>
                <div className="divider" />
                <li className="inkind-wallet-option">
                  <FormikRadioButton
                    name="inkind_wallet"
                    value={""}
                    label="Don’t use available Wallet balance"
                  />
                </li>
              </ul>
            </div>
          )}
          <button
            type="button"
            className="review-button"
            onClick={hideEditSavings}
          >
            Review Bill
          </button>
        </div>
      </div>
    </div>
  );
};
