import React, { useState } from "react";
import { CreditCard, FormValues, ProjectData } from "./type";
import { getCardIcon } from "./Receipt";
import Arrow from "./images/down-arrow.svg";
import AddCardIcon from "./images/add-card.svg";
import { useFormikContext } from "formik";

interface CardProps {
  cardInfo?: CreditCard;
  handleClick?: () => void;
  addNewcard?: boolean;
}
export const CreditCardRow: React.FC<CardProps> = ({
  cardInfo,
  handleClick,
  addNewcard = false,
}) => {
  const { brand, last_four, exp_month, exp_year } = cardInfo || {};
  return (
    <li className="card" onClick={handleClick}>
      <img
        src={
          addNewcard ? AddCardIcon : getCardIcon((brand || "").toLowerCase())
        }
        alt="credit card logo"
      />
      <span
        className="card-number"
        style={{ width: addNewcard ? "100%" : "85px" }}
      >
        {addNewcard
          ? "Add new card"
          : `${brand === "American Express" ? "AMEX" : brand} ${last_four}`}
      </span>
      {!addNewcard && (
        <>
          <span className="card-date">•</span>
          <span className="card-date">
            Exp. {exp_month?.padStart(2, "0")}/{exp_year?.slice(2)}
          </span>
        </>
      )}
    </li>
  );
};

interface AddCardProps {
  projectData: ProjectData;
}

const AddCard: React.FC<AddCardProps> = ({ projectData }) => {
  const [expended, setExpended] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const creditCards = projectData?.credit_cards;
  const handleCardClick = (cardId: number | string) => {
    setExpended(false);
    setFieldValue("credit_card_id", `${cardId || ""}`);
  };
  const selectedCard =
    creditCards.find((card) => card.id === +values.credit_card_id) ||
    ({} as CreditCard);
  const unSelectedCards =
    creditCards.filter((card) => card.id !== +values.credit_card_id) || [];

  return (
    <div className="add-card">
      <button
        className="add-card-button"
        onClick={() => setExpended((prev) => !prev)}
        type="button"
      >
        <h4 className="add-card-title">Payment Method</h4>
        <CreditCardRow
          cardInfo={selectedCard}
          addNewcard={values.credit_card_id === "new"}
        />
        <img src={Arrow} alt="arrow icon" className="add-card-arrow" />
      </button>
      {expended && !!creditCards?.length && (
        <ul className="add-card-options">
          {unSelectedCards.map((card) => (
            <CreditCardRow
              cardInfo={card}
              key={card.id}
              handleClick={handleCardClick.bind(null, card?.id)}
            />
          ))}
          {values.credit_card_id !== "new" && (
            <CreditCardRow
              handleClick={handleCardClick.bind(null, "new")}
              addNewcard={true}
            />
          )}
        </ul>
      )}
    </div>
  );
};

export default AddCard;
