import React, { useState } from "react";
import Select from "react-select";
import classNames from "classnames";
import { css } from "@emotion/css";
import includes from "lodash-es/includes";
import ArrowDropDown from "../../../../assets/images/icons/round-keyboard_arrow_down-24px.svg";

const fieldInputStyle = css`
  position: relative;
  margin-bottom: 2rem;
  font-size: 1rem;

  label {
    position: absolute;
    top: 0;
    left: 0.5rem;
    font-size: 0.875rem;
    color: #7b7b7b;
    line-height: 1;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 0.5rem;
    font-weight: 500;
  }

  .invalid-feedback {
    font-size: 0.875rem;
    color: var(--color-red);
    padding-left: 1rem;
  }
`;

const customStyles = {
  container: (provided) => ({
    ...provided,
    background: "transparent",
    border: "none",
    padding: 0,
  }),
  control: (provided, { selectProps }) => {
    const hasInvalidClassName = includes(selectProps.className, "is-invalid");

    const additionalStyles = hasInvalidClassName
      ? { borderColor: "#dc3545" }
      : {};

    return {
      ...provided,
      ...additionalStyles,
      minHeight: "var(--input-height)",
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: "0 16px",
    };
  },
  indicatorSeparator: (provided) => {
    return {
      ...provided,
      backgroundColor: "transparent",
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      top: "-8px",
    };
  },
};

const FormSelect = ({
  field,
  form: { touched, errors, setFieldValue },
  title,
  className,
  labelClassName,
  fieldClassName,
  inValidClassName,
  errorClassName,
  placeholder,
  onChange,
  defaultValue,
  options,
  value,
  ...props
}) => {
  const isError = touched[field.name] && errors[field.name];

  const handleChange = React.useCallback((option) => {
    setFieldValue(field.name, option);
    onChange(option);
  }, []);

  const [open, setOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState(defaultValue);

  const handleOpenCardInfo = (toSet) => {
    if (typeof toSet !== "undefined") {
      setOpen(toSet);
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  document.addEventListener("click", (e) => {
    if (!e.target.closest(".payment-dropdown-outer")) {
      setOpen(false);
    }
  });
  const cardInfo = cardDetails
    ? cardDetails?.label?.split(" ")
    : value?.label?.split(" ");
  return (
    <div className={classNames(className, fieldInputStyle)}>
      <div className="payment-dropdown-outer">
        <div className="payment-dropdown-container">
          <div
            className="payment-dropdown-btn"
            onClick={() => handleOpenCardInfo(true)}
          >
            <div
              className={css`
                display: flex;
              `}
            >
              <>
                {value ? (
                  <>
                    <span
                      className={css`
                        margin-bottom: 0;
                        font-family:
                          "Rethink-Sans-Bold", "Rethink-Sans-Bold", "inKind",
                          Arial, Helvetica, sans-serif;
                      `}
                    >
                      {cardInfo[0]} {cardInfo[1]}
                    </span>
                    <span>
                      &nbsp;
                      {cardInfo[2]} {cardInfo[3]} {cardInfo[4]}
                    </span>
                  </>
                ) : (
                  <>
                    <span>{value?.label}</span>
                  </>
                )}
              </>
              <img
                className={css`
                  margin-left: auto;
                `}
                src={ArrowDropDown}
              />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className="payment-dropdown-content">
          {options?.map((card) => {
            const cardData = card?.label?.split(" ");
            return (
              <div
                key={card.value}
                onClick={() => {
                  handleOpenCardInfo(false);
                  handleChange(card);
                }}
                className={css`
                  padding: 10px;
                `}
              >
                <span
                  className={css`
                    margin-bottom: 0;
                    font-family:
                      "Rethink-Sans-Bold", "Rethink-Sans-Bold", "inKind", Arial,
                      Helvetica, sans-serif;
                  `}
                >
                  {cardData[0]} {cardData[1]}
                </span>
                <span>
                  &nbsp;
                  {cardData[2]} {cardData[3]} {cardData[4]}
                </span>
              </div>
            );
          })}
        </div>
      )}
      {/* <Select
       {...field}
       {...props}
       options={options}
       styles={customStyles}
       placeholder={placeholder}
       onChange={handleChange}
       defaultValue={defaultValue}
      />  */}
      <label className={classNames(labelClassName)} htmlFor={field.name}>
        {title}
      </label>
      {isError && <div className={errorClassName}>{errors[field.name]}</div>}
    </div>
  );
};

FormSelect.defaultProps = {
  fieldClassName: "",
  inValidClassName: "is-invalid",
  errorClassName: "invalid-feedback",
  onChange: () => {},
};

export default FormSelect;
