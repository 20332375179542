import React, { useState, useRef, useMemo } from "react";
import { ErrorMessage, Form, Formik, FormikErrors, FormikProps } from "formik";
import { object, string } from "yup";
import validator from "validator";
import { FormikRadioButton } from "../../ui/atoms/formik-radio-button";
import FormikTextInput from "../../ui/atoms/formik-text-input";
import { TipOptions } from "./TipOptions";
import { PaymentMethod } from "./PaymentMethod";
import {
  AuthPayload,
  CreditCard,
  FormValues,
  PaymentCard,
  PaymentPayload,
  PaymentResponse,
  ProjectData,
  TicketData,
} from "./type";
import { useStripePaymentRequest } from "./useStripePaymentRequest";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import TipConfirmationModal from "./TipConfirmationModal";
import { calcTotalQuantity } from "./Receipt";
import { ApplyRewards, getOfferById } from "./ApplyRewards";

const setAllFieldsTouched = (
  formikRef: React.RefObject<FormikProps<FormValues>> | undefined,
) => {
  if (formikRef?.current) {
    const touchedFields = Object.keys(formikRef.current?.initialValues).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    formikRef.current.setTouched(touchedFields);
  }
};

export function parseTipAmount(tipAmount: string): number {
  if (!tipAmount || tipAmount.trim() === "") {
    return 0;
  }
  const cleanedAmount = tipAmount.replace("$", "");
  const parsedAmount = parseFloat(cleanedAmount);

  if (isNaN(parsedAmount)) {
    return 0;
  }
  return parseFloat(parsedAmount.toFixed(2));
}
function splitFullName(fullName: string) {
  const nameParts = fullName.trim().split(" ");
  if (nameParts.length < 2) {
    return {
      firstName: fullName,
      lastName: "",
    };
  }
  return {
    firstName: nameParts.slice(0, nameParts.length - 1).join(" "),
    lastName: nameParts[nameParts.length - 1],
  };
}

export const hasHighTip = (
  values: FormValues | undefined,
  tipCalculationBase: number,
) => {
  const totalTip = parseTipAmount(values?.tip_amount || "");
  const tipPercentage = totalTip / tipCalculationBase;
  const highTipOver100 = tipCalculationBase >= 100 && tipPercentage > 0.35;
  const highTipLess100 = tipCalculationBase < 100 && tipPercentage > 1;
  return highTipOver100 || highTipLess100;
};

const validateName = (value?: string) => {
  if (/\d/.test(value ?? "")) {
    return "No numbers allowed";
  }

  const specialChars = /[:/;()£$&@"!?]/g;
  if (specialChars.test(value ?? "")) {
    return "No symbols allowed";
  }

  const emojis =
    /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{1F700}-\u{1F77F}|\u{1F780}-\u{1F7FF}|\u{1F800}-\u{1F8FF}|\u{1F900}-\u{1F9FF}|\u{1FA00}-\u{1FAFF}]/u;
  if (emojis.test(value ?? "")) {
    return "No emojis allowed";
  }

  return "";
};

export type CheckoutProps = {
  posSlug?: string;
  locationId: string;
  ticketData: TicketData;
  projectData: ProjectData;
  ticketCode: string;
  locationData: {
    tippingEnabled: boolean;
    tipOptions: (number | undefined)[];
    locationsCount: number;
  };
  requiresAuthentication?: boolean;
  userToken?: string;
  returnUrl?: string;
  defaultTip?: number;
  showViewOrder: () => void;
  onSuccess: () => void;
  setCardInfo: React.Dispatch<React.SetStateAction<PaymentCard>>;
  setPaymentResponse: React.Dispatch<React.SetStateAction<PaymentResponse>>;
};
type BannerProps = {
  message: string;
  onClose: () => void;
};
const PayingBillModal: React.FC = () => {
  return (
    <div className="paying-bill-modal">
      <div className="paying-bill-modal__overlay">
        <div className="paying-bill-modal__content">
          <div className="paying-bill-modal__image" />
          <div className="paying-bill-modal__text">Paying your bill</div>
        </div>
      </div>
    </div>
  );
};

const Banner: React.FC<BannerProps> = ({ message, onClose }) => {
  return (
    <div className="banner">
      <div className="banner__message">{message}</div>
      <button onClick={onClose} className="banner__dismiss-button">
        Dismiss
      </button>
    </div>
  );
};

const createValidationSchema = (
  tippingEnabled: boolean,
  requiresAuthentication: boolean,
) =>
  object().shape({
    phone: string().when("opt_in", ([opt_in], schema) => {
      return opt_in === "opt-in"
        ? schema
            .required("Phone number is required")
            .matches(
              /^(\+1\s?)?\(?[2-9]\d{2}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              "Invalid phone number",
            )
        : schema.notRequired();
    }),
    tip_amount: string().when(
      ["payment_method"],
      ([payment_method], schema) => {
        return payment_method !== "inkind" && tippingEnabled
          ? schema
              .required("Tip amount is required")
              .matches(
                /^\$(0\.00|0\.(5[1-9]|[6-9]\d)|[1-9]\d*\.\d{2})$/,
                "A tip should be at least $0.51 and in the format $0.00",
              )
          : schema.notRequired();
      },
    ),
    payment_method: string().required("Payment method is required"),
    opt_in: string().when(["payment_method"], ([payment_method], schema) => {
      return payment_method !== "inkind" && !requiresAuthentication
        ? schema.required("Please choose one option")
        : schema.notRequired();
    }),
    first_name: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema.required("Missing first name").test(
              "valid-name",
              (value) => validateName(value.value),
              (value) => !validateName(value),
            )
          : schema.notRequired();
      },
    ),
    last_name: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema.required("Missing last name").test(
              "valid-name",
              (value) => validateName(value.value),
              (value) => !validateName(value),
            )
          : schema.notRequired();
      },
    ),
    email: string().when("payment_method", ([payment_method], schema) => {
      return payment_method === "card" && !requiresAuthentication
        ? schema
            .email("Your email is invalid.")
            .required("Missing email")
            .test("valid-email", "Your email is invalid.", (value) =>
              validator.isEmail(value),
            )
        : schema.notRequired();
    }),
    zip: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema
              .required("Missing zip code")
              .matches(/^\d{5}(-\d{4})?$/, "Invalid zip code format")
          : schema.notRequired();
      },
    ),
    card_expiry: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema
              .required("Missing card expiry")
              .test(
                "is-not-error",
                "Your card's expiration date is incomplete.",
                (value) => value !== "error",
              )
          : schema.notRequired();
      },
    ),
    card_number: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema
              .required("Missing card number")
              .test(
                "is-not-error",
                "Your card number is invalid.",
                (value) => value !== "error",
              )
          : schema.notRequired();
      },
    ),
    cvv: string().when(
      ["payment_method", "credit_card_id"],
      ([payment_method, credit_card_id], schema) => {
        return (payment_method === "card" && !requiresAuthentication) ||
          (credit_card_id === "new" && requiresAuthentication)
          ? schema
              .required("Missing CVV")
              .test(
                "is-not-error",
                "Your card's security code is incomplete.",
                (value) => value !== "error",
              )
          : schema.notRequired();
      },
    ),
  });

const initialValues: FormValues = {
  phone: "",
  tip_amount: "",
  opt_in: "",
  payment_method: "",
  first_name: "",
  last_name: "",
  email: "",
  card_expiry: "",
  card_number: "",
  cvv: "",
  zip: "",
  accept_high_tip: false,
  offer_id: "",
  inkind_wallet: "",
  total_due: 0,
  credit_card_id: "",
};

const Checkout: React.FC<CheckoutProps> = ({
  posSlug,
  locationId,
  ticketData,
  projectData,
  locationData,
  ticketCode,
  requiresAuthentication,
  userToken,
  returnUrl,
  defaultTip,
  showViewOrder,
  onSuccess,
  setCardInfo,
  setPaymentResponse,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, setErrorMsg] = useState("");
  const [submittingPayment, setSubmittingPayment] = useState(false);
  const [showTipConfirmation, setShowTipConfirmation] = useState(false);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const {
    bill_house_account_amount: billHouseAccountAmount = 0,
    bill_amount = 0,
    tip_calculation_base: tipCalculationBase = 0,
    line_items: lineItems = [],
    service_charges: serviceCharges = [],
  } = ticketData;
  const creditBackPercent = +projectData?.sub_credit_back_percent || 0.2;
  const rewardsAmount = (billHouseAccountAmount * creditBackPercent).toFixed(2);
  const autoGrat =
    serviceCharges?.find((sc) => sc.type === "gratuity")?.amount || 0;
  const billAmountDue = bill_amount - autoGrat;
  const { paymentRequest, availableMethod, updatePaymentRequestOptions } =
    useStripePaymentRequest({
      billAmount: bill_amount,
      formikRef,
      handleToken: handleGoogleApplePay,
    });
  const totalQuantity = calcTotalQuantity(lineItems);
  const authHeaders = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }),
    [userToken],
  );
  const tipFieldsDisabled = Boolean(defaultTip && defaultTip < 0);
  const isTipValid = defaultTip && defaultTip > 0.5;
  const showTipOptions = !tipFieldsDisabled && !isTipValid;

  const handleCardPayment = async (values: FormValues) => {
    if (!stripe || !elements) return;
    const name = `${values.first_name} ${values.last_name}`;
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) return;

    const { error, token } = await stripe.createToken(cardNumberElement, {
      name,
      address_zip: values.zip,
    });
    if (error) {
      throw error;
    }
    return token;
  };

  async function handleGoogleApplePay(event: any) {
    if (!/^[a-zA-Z\s'-]+$/.test(event.payerName)) {
      event.complete("fail");
      setErrorMsg("No symbols or numbers allowed in the name.");
      return;
    }
    setAllFieldsTouched(formikRef);
    const errors = await formikRef?.current?.validateForm();
    const isValid = Object.keys(errors ?? {}).length === 0;
    const isHighTip =
      hasHighTip(formikRef?.current?.values, tipCalculationBase) &&
      !formikRef?.current?.values.accept_high_tip;

    if (!isValid) {
      if (errors?.opt_in || errors?.phone) {
        window.scrollTo({
          top: 200,
          behavior: "smooth",
        });
      }
      event.complete("fail");
      setErrorMsg("One or more fields have issues.");
      return;
    }
    if (isHighTip) {
      setShowTipConfirmation(true);
      event.complete("fail");
      return;
    }
    event.complete("success");
    setErrorMsg("");
    handlePayment(formikRef?.current?.values || ({} as FormValues), event);
  }
  async function handleNoAuthPayment(values: FormValues, event: any = {}) {
    const payload: PaymentPayload = {
      project_id: projectData?.project_id,
      location_id: projectData?.location_id,
      ticket_number: ticketData?.ticket_code || "",
      customer: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      },
      payment_method: {
        stripe_token: "",
      },
      transaction: {
        amount: bill_amount,
        notes: "",
        partial_payment: false,
      },
      tip: {
        amount: parseTipAmount(values.tip_amount || "") * 100,
      },
      opt_in: {
        inkind: values.opt_in === "opt-in",
      },
      utm: {},
    };
    if (values.payment_method === "card") {
      const token = await handleCardPayment(values);
      payload.payment_method.stripe_token = token?.id ?? "";
      payload.customer = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        zip: values.zip,
      };
      setCardInfo((token?.card || {}) as PaymentCard);
    } else {
      const { firstName, lastName } = splitFullName(event.payerName);
      payload.payment_method.stripe_token = event?.token?.id ?? "";
      payload.customer = {
        first_name: firstName,
        last_name: lastName,
        email: event.payerEmail,
        phone: values.phone || event.payerPhone,
        zip: event?.token?.card?.address_zip || "",
      };
      setCardInfo(event?.token?.card || {});
    }
    const { data } = await axios.post("/api/v5/pay", payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return data;
  }
  async function addNewCard(values: FormValues, event: any = {}) {
    let token;
    if (values.payment_method === "card") {
      token = await handleCardPayment(values);
    } else {
      token = event?.token;
    }
    if (!token?.id) throw new Error("Something went wrong.");
    setCardInfo(token?.card || {});
    const { data } = await axios.post<{
      credit_cards?: CreditCard[];
      error?: string;
    }>(
      "/api/v3/current_user/stripe_sources",
      {
        stripe_token: token?.id,
      },
      authHeaders,
    );
    if (data?.error) throw new Error(data?.error);
    const cardId = data?.credit_cards?.find(
      (card) => card.last_four === token?.card?.last4,
    )?.id;
    return cardId;
  }

  async function handleAuthPayment(values: FormValues, event: any = {}) {
    let stripeSourceId: number | undefined;
    if (
      (values.payment_method === "card" && values.credit_card_id === "new") ||
      values.payment_method !== "card"
    ) {
      stripeSourceId = await addNewCard(values, event);
    } else {
      stripeSourceId = +values.credit_card_id;
      const selectedCard = projectData?.credit_cards?.find(
        (card) => card.id === stripeSourceId,
      );
      setCardInfo({
        brand: selectedCard?.brand || "",
        exp_month: +(selectedCard?.exp_month || 0),
        exp_year: +(selectedCard?.exp_year || 0),
        last4: selectedCard?.last_four || "",
      });
    }
    if (!stripeSourceId) throw new Error("Something went wrong.");
    const appliedOffer = getOfferById(values.offer_id, projectData?.offers);
    const payload: AuthPayload = {
      transaction: {
        amount: bill_amount,
        charge_stripe_source_id: stripeSourceId,
        partial_payment: !!ticketData?.bill_amount_to_leave_unpaid,
        force_overspend:
          values.inkind_wallet !== "apply" ||
          (values.inkind_wallet === "apply" &&
            (appliedOffer?.claim_details?.balance || 0) > bill_amount),
      },
      project: projectData?.project_slug,
      ticket_number: ticketData?.ticket_code || "",
      location_id:
        projectData?.location_type === "ProjectLocation"
          ? projectData?.location_id
          : null,
      tip: {
        amount: tipFieldsDisabled
          ? 0
          : isTipValid
            ? defaultTip * 100
            : parseTipAmount(values.tip_amount || "") * 100,
        stripe_source_id: stripeSourceId,
      },
    };
    if (values.offer_id) payload.transaction.offer_claim_id = +values.offer_id;
    if (posSlug) payload.pos = posSlug;
    const { data } = await axios.post(
      `/api/v3/current_user/credits`,
      payload,
      authHeaders,
    );
    return data;
  }

  async function handlePayment(values: FormValues, event: any = {}) {
    try {
      setSubmittingPayment(true);
      setErrorMsg("");
      let data = {} as PaymentResponse;
      if (requiresAuthentication) {
        data = await handleAuthPayment(values, event);
      } else {
        data = await handleNoAuthPayment(values, event);
      }
      if (!data?.pos_success) throw new Error("Something went wrong.");
      if (returnUrl) {
        window.location.replace(data?.return_url_override || returnUrl);
        return;
      }
      setPaymentResponse(data);
      onSuccess();
    } catch (error) {
      const errorMessage = axios.isAxiosError(error)
        ? error?.response?.data?.errors?.[0].message || error.message
        : error.message;
      setErrorMsg(errorMessage);
    } finally {
      setSubmittingPayment(false);
    }
  }

  const handleSubmit = (values: FormValues) => {
    if (values.accept_high_tip || !hasHighTip(values, tipCalculationBase)) {
      handlePayment(values);
      return;
    }
    setShowTipConfirmation(true);
  };

  const handleCountinuePayment = (
    values: FormValues,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<FormValues>>,
  ) => {
    setFieldValue("accept_high_tip", true);
    setErrorMsg("");
    if (values.payment_method === "card") return handlePayment(values);
    else if (paymentRequest) {
      return paymentRequest.show();
    }
  };

  return (
    <>
      <div className="checkout">
        <div className="header">
          <div className="checkout-text">Checkout</div>
          <button className="view-order-button" onClick={showViewOrder}>
            <div className="receipt-icon" />
            <span>View order</span>
          </button>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={createValidationSchema(
            locationData?.tippingEnabled && showTipOptions,
            !!requiresAuthentication,
          )}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="form">
              {!requiresAuthentication && (
                <div className="section">
                  <div className="title">
                    Join inKind Rewards to get{" "}
                    <span className="highlight">${rewardsAmount}</span> Back on
                    your meal today
                  </div>
                  <div className="sub-title">
                    Spend your ${rewardsAmount} Reward at{" "}
                    {locationData?.locationsCount}+ restaurants
                  </div>
                  <div className="opt-in-container">
                    <div className="opt-in">
                      <div className="highlight">Yes, Join inKind Rewards</div>
                      <FormikRadioButton
                        name="opt_in"
                        value="opt-in"
                        className="opt-in-radio"
                        label="I agree to receive text messages from inKind, including verification & marketing messages at the phone number provided. Reply STOP to cancel. Message & data rates may apply. "
                      />
                      {values.opt_in === "opt-in" && (
                        <FormikTextInput label="Phone Number" name="phone" />
                      )}
                    </div>
                    <div className="opt-out">
                      <FormikRadioButton
                        name="opt_in"
                        value="opt-out"
                        label={`No thanks, I don’t want $${rewardsAmount} Back`}
                      />
                    </div>
                    <ErrorMessage
                      name="opt_in"
                      component="div"
                      className="opt-in__error"
                    />
                  </div>
                </div>
              )}
              <div className="section">
                <div className="bill-total">
                  <span>
                    Bill Total ({totalQuantity} Item
                    {totalQuantity > 1 ? "s" : ""})
                  </span>
                  <span>${billAmountDue.toFixed(2)}</span>
                </div>
                {locationData?.tippingEnabled && showTipOptions && (
                  <TipOptions
                    tipCalculationBase={tipCalculationBase}
                    tipOptions={locationData?.tipOptions || []}
                    hasAutomaticGratuity={!!autoGrat}
                  />
                )}
              </div>
              {requiresAuthentication && (
                <ApplyRewards
                  projectData={projectData}
                  billTotal={billAmountDue}
                  ticketData={ticketData}
                  defaultTip={
                    showTipOptions
                      ? undefined
                      : tipFieldsDisabled
                        ? 0
                        : defaultTip
                  }
                  autoGrat={autoGrat}
                />
              )}
              <PaymentMethod
                paymentRequest={paymentRequest}
                availableMethod={availableMethod}
                billAmount={billAmountDue}
                requiresAuthentication={requiresAuthentication}
                creditBackPercent={creditBackPercent}
                projectData={projectData}
                updatePaymentRequestOptions={updatePaymentRequestOptions}
                autoGrat={autoGrat}
              />
              <TipConfirmationModal
                isOpen={showTipConfirmation}
                tipAmount={parseTipAmount(values?.tip_amount || "")}
                autoGrat={autoGrat}
                tipCalculationBase={tipCalculationBase}
                closeModal={() => setShowTipConfirmation(false)}
                continuePayment={() =>
                  handleCountinuePayment(values, setFieldValue)
                }
              />
            </Form>
          )}
        </Formik>
      </div>
      {submittingPayment && <PayingBillModal />}
      {errorMsg && (
        <Banner message={errorMsg} onClose={() => setErrorMsg("")} />
      )}
    </>
  );
};

export default Checkout;
