import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { FormikProps } from "formik";
import { AvailablePaymentMethod, FormValues } from "./type";
import { parseTipAmount } from "./Checkout";

interface UseStripePaymentRequestParams {
  billAmount: number;
  formikRef: React.RefObject<FormikProps<FormValues>> | undefined;
  handleToken: (event: any) => void;
}

export const useStripePaymentRequest = ({
  formikRef,
  billAmount,
  handleToken,
}: UseStripePaymentRequestParams) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const [availableMethod, setAvailableMethod] =
    useState<AvailablePaymentMethod>("");

  useEffect(() => {
    if (!stripe || paymentRequest) return;

    const setupPaymentRequest = async () => {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: Math.round(billAmount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      try {
        const result = await pr.canMakePayment();
        if (result) {
          setPaymentRequest(pr);
          if (result.applePay) {
            setAvailableMethod("Apple Pay");
            formikRef?.current?.setFieldValue("payment_method", "Apple Pay");
          } else if (result.googlePay) {
            setAvailableMethod("Google Pay");
            formikRef?.current?.setFieldValue("payment_method", "Google Pay");
          } else {
            formikRef?.current?.setFieldValue("payment_method", "card");
          }

          pr.on("token", async (event) => {
            handleToken(event);
          });
        } else {
          formikRef?.current?.setFieldValue("payment_method", "card");
        }
      } catch (error) {
        console.log(error);
        formikRef?.current?.setFieldValue("payment_method", "card");
      }
    };

    setupPaymentRequest();

    return () => {
      if (paymentRequest) {
        paymentRequest.off("token");
      }
    };
  }, [stripe, formikRef, billAmount, handleToken]);

  const updatePaymentRequestOptions = (totalDue: string) => {
    if (paymentRequest) {
      const totalAmount = Math.round(+totalDue * 100);
      paymentRequest.update({
        total: {
          label: "Total",
          amount: totalAmount,
        },
      });
    }
  };

  return { paymentRequest, availableMethod, updatePaymentRequestOptions };
};
