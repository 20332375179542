import React from "react";
import NewLoginCreateAccount from "../new-login-create-account/NewLoginCreateAccount.jsx";

const CheckoutLogin = ({ country, onComplete }) => {
  return (
    <div className="bsl-login">
      <NewLoginCreateAccount
        country={country}
        isBslCheckout={true}
        setUser={onComplete}
        siteArea={"redeem_checkout"}
      />
    </div>
  );
};

export default CheckoutLogin;
