import React, { useEffect, useMemo, useState } from "react";
import { FormValues, Offer, ProjectData, TicketData } from "./type";
import OneRewardIcon from "./images/one-reward-icon.svg";
import TwoRewardsIcon from "./images/two-rewards-icon.svg";
import ArrowRightWhite from "./images/arrow-right-white.svg";
import { useFormikContext } from "formik";
import { EditSavings } from "./EditSavings";
import { parseTipAmount } from "./Checkout";

export const parseRedeemableDays = (
  redeemable_days: string | null,
): string[] => {
  if (!redeemable_days) return [];

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [startDay, endDay] = redeemable_days.split("-");

  const startIndex = daysOfWeek.indexOf(startDay.trim());
  const endIndex = daysOfWeek.indexOf(endDay.trim());

  if (startIndex === -1 || endIndex === -1) return [];

  if (startIndex <= endIndex) {
    return daysOfWeek.slice(startIndex, endIndex + 1);
  } else {
    return [
      ...daysOfWeek.slice(startIndex),
      ...daysOfWeek.slice(0, endIndex + 1),
    ];
  }
};

export const isRedeemableToday = (redeemable_days: string | null): boolean => {
  if (!redeemable_days) return true;

  const today = new Date().toLocaleString("en-US", { weekday: "long" });
  const validDays = parseRedeemableDays(redeemable_days);

  return validDays.includes(today);
};

export const addIneligibleReasons = (
  offers: Offer[],
  billTotal: number,
): Offer[] => {
  const filteredOfferList =
    offers?.filter(
      (offer) =>
        offer.expiration?.mode === "no_expiration" ||
        new Date(offer.claim_details?.expires_at || "").getTime() >
          new Date().getTime(),
    ) || [];
  return filteredOfferList.map((offer) => {
    if (offer.ineligableReason) return offer;
    let ineligableReason = "";

    if (
      offer.minimum_spend_required &&
      billTotal < offer.minimum_spend_amount
    ) {
      ineligableReason = "Minimum not met.";
    }

    if (!(offer.restrict_to_dine_in || !offer.restrict_by_order_type)) {
      ineligableReason = "Order type not met.";
    }

    if (!isRedeemableToday(offer.redeemable_days)) {
      ineligableReason = offer.redeemable_days || "Redeemable days not met.";
    }

    return {
      ...offer,
      ineligableReason,
    };
  });
};

const getRewardsObj = (
  inkindWallet: string,
  appliedOffer: Offer | undefined,
  billTotal: number,
): Record<string, string> => {
  const rewardsObj = {};
  if (appliedOffer) {
    rewardsObj[appliedOffer.name] = Math.min(
      appliedOffer?.claim_details?.balance,
      billTotal,
    ).toFixed(2);
  }

  if (+inkindWallet > 0)
    rewardsObj["inKind Wallet"] = (+inkindWallet).toFixed(2);
  return rewardsObj;
};

export const getOfferById = (offerId: string, offers: Offer[]) => {
  return offers.find(
    (offer) => offer?.claim_details?.offer_claim_id === +offerId,
  );
};

export const getDefaultOffer = (
  projectData: ProjectData,
  validatedOffers: Offer[],
) => {
  const isProjectOfferValid = !validatedOffers.find(
    (offer) => projectData?.offer?.id === offer.id,
  )?.ineligableReason;
  return projectData?.offer && isProjectOfferValid
    ? projectData?.offer
    : validatedOffers.filter((offer) => !offer.ineligableReason)?.[0] || {};
};

interface ApplyRewardsProps {
  projectData: ProjectData;
  billTotal: number;
  defaultTip?: number;
  ticketData: TicketData;
  autoGrat: number;
}

export const ApplyRewards: React.FC<ApplyRewardsProps> = ({
  projectData,
  billTotal,
  defaultTip,
  ticketData,
  autoGrat,
}) => {
  const [showEditSavings, setShowEditSavings] = useState(false);
  const [showSelect, setShowSelect] = useState(true);
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const validatedOffers = useMemo(
    () => addIneligibleReasons(projectData?.offers || [], billTotal),
    [projectData, billTotal, ticketData],
  );
  const defaultOffer = getDefaultOffer(projectData, validatedOffers);
  const defaultOfferId =
    defaultOffer?.claim_details?.offer_claim_id?.toString() || "";
  const appliedOffer = getOfferById(values.offer_id, validatedOffers);
  const billTotalMinusdefaultOffer =
    billTotal - (defaultOffer?.claim_details?.balance || 0);
  const totalInkindBalance =
    (projectData?.credit_balance || 0) + (projectData?.ik_pass_balance || 0);
  const availableBalance = Math.max(
    Math.min(
      billTotal - (appliedOffer?.claim_details?.balance || 0),
      totalInkindBalance,
    ),
    0,
  );
  const initialAvailableBalance = Math.min(
    billTotalMinusdefaultOffer,
    totalInkindBalance,
  );

  useEffect(() => {
    setFieldValue("offer_id", defaultOfferId);
    if (initialAvailableBalance > 0 && billTotalMinusdefaultOffer > 0)
      setFieldValue("inkind_wallet", "apply");
    setFieldValue(
      "credit_card_id",
      `${projectData?.credit_cards?.[0]?.id || "new"}`,
    );
  }, [projectData, billTotal, validatedOffers, defaultOfferId, ticketData]);
  const rewardsObj = getRewardsObj(
    `${values.inkind_wallet ? availableBalance : ""}`,
    appliedOffer,
    billTotal,
  );
  const totalSavings = (+Object.values(rewardsObj).reduce((sum, value) => {
    return sum + parseFloat(value);
  }, 0)).toFixed(2);

  const totalDue = (
    Math.max(billTotal - +totalSavings, 0) +
    (defaultTip ?? parseTipAmount(values.tip_amount) + autoGrat)
  ).toFixed(2);

  useEffect(() => {
    setFieldValue("total_due", totalDue);
  }, [totalDue]);

  const applySaving = values.offer_id || values.inkind_wallet;
  const numberOfTotalSavings =
    totalInkindBalance > 0
      ? validatedOffers.length + 1
      : validatedOffers.length;
  const numberOfAppliedSavings = Object.keys(rewardsObj).length;
  const numberOfIneligibleOffers = validatedOffers.filter(
    (offer) => offer.ineligableReason !== "",
  ).length;
  return (
    <>
      {(!!defaultOfferId ||
        !!availableBalance ||
        !!validatedOffers?.length) && (
        <div className="section apply-rewards">
          <div className="apply-rewards__title">Apply Rewards</div>
          <div className="apply-rewards__buttons">
            <button
              className={`apply-rewards__button ${applySaving ? "selected" : ""}`}
              onClick={() => {
                setShowSelect(true);
                setFieldValue("offer_id", defaultOfferId);
                if (
                  initialAvailableBalance > 0 &&
                  billTotalMinusdefaultOffer > 0
                )
                  setFieldValue("inkind_wallet", "apply");
              }}
              type="button"
            >
              <span>Apply Savings</span>
              <span className="apply-rewards__button-subtext">
                Use Balances & Offers
              </span>
            </button>
            <button
              className={`apply-rewards__button ${!applySaving ? "selected" : ""}`}
              onClick={() => {
                setFieldValue("offer_id", "");
                setFieldValue("inkind_wallet", "");
                setShowSelect(false);
              }}
              type="button"
            >
              <span>
                Maximize {(+projectData?.sub_credit_back_percent || 0.2) * 100}%
                Back
              </span>
              <span className="apply-rewards__button-subtext">
                Charge full amount to card
              </span>
            </button>
          </div>
          {(!!numberOfAppliedSavings || showSelect) && (
            <div className="apply-rewards__content">
              {!!numberOfAppliedSavings && (
                <div className="apply-rewards__content-top">
                  <div className="apply-rewards__content-title">
                    Saving <span className="highlight">${totalSavings}</span>{" "}
                    with inKind today
                  </div>
                  <div className="apply-rewards__content-details-container">
                    {Object.entries(rewardsObj).map(([key, value], index) => {
                      return (
                        <div
                          className="apply-rewards__content-details"
                          key={key}
                        >
                          <div className="apply-rewards__content-details-left">
                            <img
                              src={index === 0 ? OneRewardIcon : TwoRewardsIcon}
                              alt="inkind wallet icon"
                            />
                            <span>{key}</span>
                          </div>
                          <div className="apply-rewards__content-details-right">
                            {`- $${value}`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {showSelect && (
                <button
                  className="apply-rewards__content-bottom"
                  onClick={() => setShowEditSavings(true)}
                  type="button"
                >
                  <div className="apply-rewards__content-bottom-left">
                    {numberOfAppliedSavings}/{numberOfTotalSavings} Savings
                    applied
                    {!!numberOfIneligibleOffers && (
                      <>
                        <span>•</span>
                        <span className="ineligible">
                          {numberOfIneligibleOffers} ineligible
                        </span>
                      </>
                    )}
                  </div>
                  <div className="apply-rewards__content-bottom-right">
                    {numberOfAppliedSavings > 0 ? "Select" : "See why"}
                    <img src={ArrowRightWhite} alt="arrow-right" />
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <div className="section bsl-bill-total">
        <div className="bsl-bill-total--title">
          <span>Bill Total</span>
          <div>
            {!!+totalSavings && (
              <span className="original-total">${billTotal.toFixed(2)}</span>
            )}
            <span>${Math.max(billTotal - +totalSavings, 0).toFixed(2)}</span>
          </div>
        </div>
        {autoGrat > 0 && (
          <div className="bsl-bill-total--sub-title">
            <span>Automatic Gratuity</span>
            <span>${autoGrat?.toFixed(2)}</span>
          </div>
        )}
        <div className="bsl-bill-total--sub-title">
          <span>{autoGrat > 0 ? "Additional " : ""}Tip</span>
          <span>{defaultTip?.toFixed(2) ?? values.tip_amount}</span>
        </div>
        <div className="divider" />
        <div className="bsl-bill-total--total-due">
          <span>Total Due</span>
          <span>${totalDue}</span>
        </div>
      </div>
      <EditSavings
        showEditSavings={showEditSavings}
        hideEditSavings={() => setShowEditSavings(false)}
        projectData={projectData}
        billTotal={billTotal}
        availableBalance={availableBalance}
      />
    </>
  );
};
