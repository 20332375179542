import React, { useEffect } from "react";
import {
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";
import {
  FormikRadioButton,
  FormikRadioButtonProps,
} from "../../ui/atoms/formik-radio-button";
import { ErrorMessage, useFormikContext } from "formik";
import FormikTextInput from "../../ui/atoms/formik-text-input";
import PayByCardDarkIcon from "./images/pay-by-card-dark.svg";
import PayByCardLightIcon from "./images/pay-by-card-light.svg";
import GooglePayIconWhite from "./images/google-pay-white.svg";
import GooglePayIconBlack from "./images/google-pay-black.svg";
import ApplePayIconBlack from "./images/apple-pay-black.svg";
import ApplePayIconWhite from "./images/apple-pay-white.svg";
import InkindIconWhite from "./images/inkind-logo-white.svg";
import InkindIconBlack from "./images/inkind-logo-black.svg";
import CreditCardIconGrey from "./images/credit-card-grey.svg";
import CreditCardIconBlack from "./images/credit-card-black.svg";
import RewardsIcon from "./images/rewards-icon.svg";
import SecurityIcon from "./images/security.svg";
import useColorScheme from "../../hooks/useColorScheme";
import { AvailablePaymentMethod, FormValues, ProjectData } from "./type";
import { cleanClassNames } from "../../helpers/classNames";
import { parseTipAmount } from "./Checkout";
import AddCard from "./AddCard";

const paymentMethodIconLookup = {
  "Apple Pay": {
    light: ApplePayIconBlack,
    dark: ApplePayIconWhite,
  },
  "Google Pay": {
    light: GooglePayIconBlack,
    dark: GooglePayIconWhite,
  },
};

interface PaymentMethodOptionProps extends FormikRadioButtonProps {
  iconSrc: string;
  alt: string;
  className?: string;
}

const PaymentMethodOption: React.FC<PaymentMethodOptionProps> = ({
  iconSrc,
  alt,
  className,
  ...props
}) => {
  return (
    <>
      <FormikRadioButton {...props} />
      <img
        src={iconSrc}
        alt={alt}
        className={cleanClassNames("payment-method__option__icon", className)}
      />
    </>
  );
};

const elementOptions = {
  style: {
    base: {
      fontFamily: '"Rethink Sans Medium", sans-serif',
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#0f0f0f",
      "::placeholder": {
        color: "transparent",
      },
      ":-webkit-autofill": {
        color: "#fff",
      },
      ":-webkit-autofill:hover": {
        color: "#fff",
      },
      ":-webkit-autofill:focus": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#ff5050",
    },
  },
};

const elementOptionsLight = {
  style: {
    base: {
      fontFamily: '"Rethink Sans Medium", sans-serif',
      fontSize: "16px",
      color: "#0f0f0f",
      backgroundColor: "#f7f7f7",
      "::placeholder": {
        color: "transparent",
      },
    },
    invalid: {
      color: "#ff5050",
    },
  },
};

export const getAppStoreUrl = () => {
  const baseUrl = window.location.hostname;
  return baseUrl.includes("localhost")
    ? "http://app.localhost:3000/"
    : baseUrl.includes("inkind-staging")
      ? "https://app.inkind-staging.com/"
      : "https://app.inkind.com/";
};

interface PaymentMethodProps {
  paymentRequest: any;
  availableMethod: AvailablePaymentMethod;
  billAmount: number;
  requiresAuthentication?: boolean;
  creditBackPercent: number;
  projectData: ProjectData;
  updatePaymentRequestOptions: (tipAmount: string) => void;
  autoGrat: number;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  paymentRequest,
  availableMethod,
  billAmount,
  requiresAuthentication,
  creditBackPercent,
  projectData,
  updatePaymentRequestOptions,
  autoGrat,
}) => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const tip = parseTipAmount(values?.tip_amount || "");
  const totalDue = requiresAuthentication
    ? values.total_due
    : (billAmount + tip).toFixed(2);
  const rewardsAmount = (
    (+totalDue - tip - autoGrat) *
    creditBackPercent
  ).toFixed(2);
  const colorScheme = useColorScheme();
  const isLightMode = colorScheme === "light";
  // const currentElementOptions = isLightMode ? elementOptionsLight : elementOptions;
  const currentElementOptions = elementOptions;

  useEffect(() => {
    updatePaymentRequestOptions(`${totalDue}`);
  }, [totalDue, paymentRequest]);

  useEffect(() => {
    if (requiresAuthentication) return;
    if (isSubmitting && (errors.opt_in || errors.phone)) {
      window.scrollTo({
        top: 250,
        behavior: "smooth",
      });
    }
  }, [isSubmitting]);
  const handleChange = (
    field: string,
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
  ) => {
    let status: string;
    if (e.complete) {
      status = "complete";
    } else if (!e.empty) {
      status = "error";
    } else {
      status = "";
    }
    setFieldValue(field, status);
  };
  return (
    <>
      <div className="payment-method__section section">
        <div className="payment-method__text">Payment Method</div>
        <div className="payment-method__options">
          <fieldset
            aria-labelledby="radio-group"
            className="payment-method__radio-group"
          >
            {paymentRequest && !!availableMethod && (
              <div className="payment-method__option">
                <PaymentMethodOption
                  name="payment_method"
                  value={availableMethod}
                  label={availableMethod}
                  iconSrc={paymentMethodIconLookup[availableMethod].dark}
                  alt={availableMethod}
                  className={availableMethod.toLowerCase().replace(" ", "-")}
                />
              </div>
            )}
            <div className="payment-method__card-option">
              <div className="payment-method__card-radio">
                <PaymentMethodOption
                  name="payment_method"
                  value="card"
                  label="Pay by Card"
                  // iconSrc={isLightMode ? PayByCardLightIcon : PayByCardDarkIcon}
                  iconSrc={PayByCardDarkIcon}
                  alt="Credit card"
                />
              </div>
              {values.payment_method === "card" && (
                <>
                  {requiresAuthentication && (
                    <AddCard projectData={projectData} />
                  )}
                  {(values.credit_card_id === "new" ||
                    !requiresAuthentication) && (
                    <>
                      <div className="payment-method__billing-info">
                        <div className="payment-method__title">
                          Billing Information
                        </div>
                        <div className="payment-method__name">
                          <FormikTextInput
                            label="First Name"
                            name="first_name"
                          />
                          <FormikTextInput label="Last Name" name="last_name" />
                        </div>
                        <div className="ui-formik-text-input">
                          <div
                            className={`ui-formik-text-input__input-container ${
                              errors.card_number &&
                              touched.card_number &&
                              "ui-formik-text-input__input--error"
                            }`}
                          >
                            <CardNumberElement
                              options={currentElementOptions}
                              onBlur={() =>
                                setFieldTouched("card_number", true)
                              }
                              onChange={(e) => handleChange("card_number", e)}
                              className="ui-formik-text-input__input has-icon"
                            />
                            <span className="ui-formik-text-input__icon">
                              <img
                                // src={
                                //   isLightMode
                                //     ? CreditCardIconBlack
                                //     : CreditCardIconGrey
                                // }
                                src={CreditCardIconGrey}
                                alt="Credit Card Icon"
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="card_number"
                            component="div"
                            className="ui-formik-text-input__error"
                          />
                        </div>
                        <div className="payment-method__card-row">
                          <div className="ui-formik-text-input">
                            <div
                              className={`ui-formik-text-input__input-container ${
                                errors.card_expiry &&
                                touched.card_expiry &&
                                "ui-formik-text-input__input--error"
                              }`}
                            >
                              <CardExpiryElement
                                id="card-expiry"
                                options={currentElementOptions}
                                onBlur={() =>
                                  setFieldTouched("card_expiry", true)
                                }
                                onChange={(e) => handleChange("card_expiry", e)}
                                className="ui-formik-text-input__input"
                              />
                              <label
                                htmlFor="card-expiry"
                                className="ui-formik-text-input__label"
                              >
                                Exp
                              </label>
                            </div>
                            <ErrorMessage
                              name="card_expiry"
                              component="div"
                              className="ui-formik-text-input__error"
                            />
                          </div>
                          <FormikTextInput label="Zip" name="zip" />
                          <div className="ui-formik-text-input">
                            <div
                              className={`ui-formik-text-input__input-container ${
                                errors.cvv &&
                                touched.cvv &&
                                "ui-formik-text-input__input--error"
                              }`}
                            >
                              <CardCvcElement
                                id="card-cvv"
                                options={currentElementOptions}
                                onBlur={() => setFieldTouched("cvv", true)}
                                onChange={(e) => handleChange("cvv", e)}
                                className="ui-formik-text-input__input"
                              />
                              <label
                                className="ui-formik-text-input__label"
                                htmlFor="card-cvv"
                              >
                                CVV
                              </label>
                            </div>
                            <ErrorMessage
                              name="cvv"
                              component="div"
                              className="ui-formik-text-input__error"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="divider"></div>
                      {!requiresAuthentication && (
                        <div className="payment-method__email">
                          <FormikTextInput label="Email" name="email" />
                          <span>We’ll send your receipt here.</span>
                        </div>
                      )}
                      {requiresAuthentication && (
                        <div className="payment-method__card-footer">
                          <img
                            src={SecurityIcon}
                            alt="Bank Level Security Icon"
                          />
                          <div className="payment-method__card-footer-text">
                            <span>Bank Level Security</span>
                            <span>inKind doesn’t store your card details</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {!requiresAuthentication && (
              <div className="payment-method__option">
                <PaymentMethodOption
                  name="payment_method"
                  value="inkind"
                  label="Pay with inKind"
                  // iconSrc={isLightMode ? InkindIconBlack : InkindIconWhite}
                  iconSrc={InkindIconWhite}
                  alt="inkind logo"
                />
              </div>
            )}
          </fieldset>
        </div>
      </div>
      <div className="payment-method__section payment-method__total-footer section">
        {requiresAuthentication && +rewardsAmount > 0 && (
          <>
            <div className="payment-method__title">Rewards</div>
            <div className="payment-method__rewards">
              <img src={RewardsIcon} alt="rewards icon" />
              <div>
                You’ll earn <span>${rewardsAmount} </span>Back
              </div>
            </div>
          </>
        )}
        {!(
          values.payment_method === "card" || values.payment_method === "inkind"
        ) &&
          paymentRequest && (
            <>
              {!requiresAuthentication && (
                <div className="payment-method__total-due">
                  <span>Total due</span>
                  <span>${totalDue}</span>
                </div>
              )}
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      // theme: isLightMode ? "dark" : "light",
                      theme: "light",
                      height: "56px",
                    },
                  },
                }}
              />
            </>
          )}
        {values.payment_method === "card" && (
          <button type="submit" className="payment-method__submit-button">
            Pay ${totalDue}
          </button>
        )}
        {values.payment_method === "inkind" && (
          <a
            href={getAppStoreUrl()}
            className="payment-method__submit-button payment-method__submit-button--inkind"
            type="button"
          >
            Download the inKind app
          </a>
        )}
      </div>
    </>
  );
};
