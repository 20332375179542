import React, { Suspense, lazy, useEffect, useState } from "react";
import useCookie from "@lib/use-cookie";
import { LocationData, ProjectData, TicketData } from "./type";
import CheckNotFound from "./CheckNotFound";
import SingleBill from "./SingleBill";
import { Splash } from "./Splash";
import CheckoutLogin from "./CheckoutLogin";
import useFetchApi from "../../hooks/useFetchApi";
const MultipleBills = lazy(() => import("./MultipleBills"));

type WebRedemptionProps = {
  posSlug: string;
  location: string;
  projectInit?: ProjectData;
  ticketInit?: TicketData[];
  locationInit?: LocationData[];
  ticket: string;
  appStoreUrl: string;
  playStoreUrl: string;
  locationsCount: number;
  requiresAuthentication?: boolean;
  token?: string;
  returnUrl?: string;
  tip?: number;
};

const WebRedemption: React.FC<WebRedemptionProps> = ({
  projectInit,
  locationInit,
  ticketInit,
  ticket,
  locationsCount,
  posSlug,
  location,
  requiresAuthentication,
  returnUrl,
  tip,
}) => {
  const [cookie] = useCookie("userToken", null);
  const [userToken, setUserToken] = useState<string>(cookie);
  const {
    data: projectData,
    error: projectError,
    loading: projectLoading,
    fetchData: fetchProjectData,
  } = useFetchApi<{ data: ProjectData }>();
  const {
    data: ticketData,
    error: ticketError,
    loading: ticketLoading,
    fetchData: fetchTicketData,
  } = useFetchApi<TicketData>();
  const loading = projectLoading || ticketLoading;
  const error = projectError || ticketError;

  useEffect(() => {
    const handlePopState = () => {
      window.location.reload();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (!requiresAuthentication || !userToken) return;
    fetchProjectData(`/api/v4/external_locations/${posSlug}/${location}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
  }, [userToken, requiresAuthentication, posSlug, location]);

  useEffect(() => {
    if (!requiresAuthentication || !userToken || !projectData) return;
    fetchTicketData(
      projectData?.data?.location_type === "Project"
        ? `/api/v4/projects/${projectData?.data?.project_id}/tickets/${ticket}?pos=${posSlug}`
        : `/api/v4/projects/${projectData?.data?.project_id}/tickets/${ticket}?pos=${posSlug}&location_id=${projectData?.data?.location_id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      },
    );
  }, [projectData, ticket, posSlug, userToken]);

  const logoUrl = projectInit?.logo_path;
  const validTickets = ticketInit?.filter((ticket) => ticket.bill_amount > 0);
  const toBeAuthenticated = requiresAuthentication && !userToken;

  return (
    <main
      className={`web-redemption ${requiresAuthentication ? "bsl-checkout" : ""}`}
    >
      {toBeAuthenticated && (
        <CheckoutLogin
          country={projectInit?.country_code}
          onComplete={(user: any) => {
            setUserToken(user.token);
          }}
        />
      )}
      {!toBeAuthenticated && loading && <Splash logoSrc={logoUrl} />}
      {!toBeAuthenticated && !loading && error && (
        <CheckNotFound logoSrc={logoUrl} returnUrl={returnUrl} />
      )}
      {!toBeAuthenticated && !loading && !error && (
        <>
          {!validTickets?.length && (
            <CheckNotFound logoSrc={logoUrl} returnUrl={returnUrl} />
          )}
          {validTickets?.length === 1 && (
            <SingleBill
              projectInit={
                requiresAuthentication ? projectData?.data : projectInit
              }
              locationInit={locationInit}
              ticketInit={
                requiresAuthentication && ticketData
                  ? ticketData
                  : validTickets?.[0]
              }
              ticket={ticket}
              locationsCount={locationsCount}
              posSlug={posSlug}
              location={location}
              requiresAuthentication={requiresAuthentication}
              userToken={userToken}
              returnUrl={returnUrl}
              defaultTip={tip}
            />
          )}
          {!!validTickets?.length && validTickets?.length > 1 && (
            <Suspense fallback={<Splash logoSrc={logoUrl} />}>
              <MultipleBills ticketDataArr={validTickets} />
            </Suspense>
          )}
        </>
      )}
    </main>
  );
};

export default WebRedemption;
